import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  SelectFieldGroup,
} from "../../../utils/Input";
import FileUpload from '../../../components/common/FileUpload'

import { Row, Col, Button } from "shards-react";
import { MESES, ANIOS, BARRIOS_COMUNAS } from "../../../utils/constants";

export default ({
  values,
  onSubmit,
}) => {
  const [pdfFile, setPdfFile] = useState(null);
  
  const fileToBase64 = file => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }    
  };

  const onDrop = (ready, file, setFieldValueMethod) => {
    if (file) {
      file.map(f => {
        if (f) {
          fileToBase64(f).then(file64 => {
            file64 = {
              name: f.name,
              value: file64,
            };
            //esto es para el update
            //values.archivoPDF = [...values.archivoPDF, file64];
            setPdfFile(file64);
            setFieldValueMethod("archivoPDF", file64);
          });   
        }
        return null;
      });
    }    
  }

  const validate = (formValues) => {
    const errors = {};

    if (!formValues.comuna) {
      errors.comuna = "Por favor ingrese comuna";
    }

    if (!formValues.mes) {
      errors.mes = "Por favor ingrese mes";
    }

    if (!formValues.anio) {
      errors.anio = "Por favor ingrese año";
    }

    if (pdfFile == null) {
      errors.archivoPDF = "Por favor ingrese Link del PDF";
    }
    
    return errors;
  }
  
  const getYearOptions = () => {
    const yearOptions = [];
    yearOptions.push({value: null, label: "- Seleccione -"});
    ANIOS.forEach((a) => yearOptions.push({value: a, label: a}));
    return yearOptions;
  }

  const getMonthOptions = () => {
    const monthOptions = [];
    monthOptions.push({value: null, label: "- Seleccione -"});
    MESES.forEach((m, i) => monthOptions.push({value: i, label: m}));
    return monthOptions;
  }

  const getComunasOptions = () => {
    const comunasOptions = [];
    comunasOptions.push({value: null, label: "- Seleccione -"});
    Object.keys(BARRIOS_COMUNAS).forEach((c) => comunasOptions.push({value: c, label: c}));
    return comunasOptions;
  }

  return (
    <div>
      <Formik
        initialValues={{
          mes: values && values.mes,
          anio: values && values.anio,
          archivoPDF: values && values.archivoPDF,
          comuna: values && values.comuna
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue
        }) => (
          <>
            <Form onSubmit={handleSubmit} className="form-container">
              <Row>
                <Col>
                  <SelectFieldGroup
                    label="Comuna"
                    name="comuna"
                    arrayOps={getComunasOptions()}
                    value={values.comuna}
                  />
                </Col>
                <Col>
                  <SelectFieldGroup
                    label="Mes"
                    name="mes"
                    arrayOps={getMonthOptions()}
                    value={values.mes}
                  />
                </Col>
                <Col>
                  <SelectFieldGroup
                    label="Año"
                    name="anio"
                    arrayOps={getYearOptions()}
                    value={values.anio}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUpload 
                    name="archivoPDF"
                    acceptedFiles={['application/pdf']}
                    onDrop={(ready, file) => onDrop(ready, file, setFieldValue)}
                    sizeLimit={5000000}
                    maxFiles={1}
                  />
                </Col>
              </Row>
              <div className="center text-center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Enviar
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}