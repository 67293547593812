/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import {
  fetchGetAllPropiedades,
  fetchGetAllClientes,
  fetchPostUpdatePropiedad,
  fetchClearAction,
  fetchDeletePropiedad,
  getAllProyectos,
  getPropiedadesPaginadas,
  initializeMensaje,
  getUsuariosFiltrados,
  getSuscripciones,
  getExcelYapo,
  setExcelYapo
} from "../../../action";
import Launch from "@material-ui/icons/Launch";
import TablaPropiedades from "./TablaPropiedades";
import IconButton from "@material-ui/core/IconButton";
import TablePagination from "@material-ui/core/TablePagination";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Container, Row, Col, FormSelect, Card } from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import { ESTADOS_PROPIEDAD } from "../../../utils/constants";
import EditIcon from "@material-ui/icons/Edit";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import FaceIcon from "@material-ui/icons/Face";
import GetAppIcon from "@material-ui/icons/GetApp";
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

export class IndexListarPropiedades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      editModal: false,
      idProp: "",
      cliente: "",
      filtro: "",
      rowsPerPage: 10,
      page: 0,
      totalResults: 0,
      modalCambioEstado: false,
      propiedadSeleccionada: {},
      filtroEstado: "",
      filtroCliente: "",
      tieneBroker: "todos",
      sortBy: "createdAtDescending"
    };
    const { getUsuariosFiltrados, getSuscripciones } = this.props;
    //traemos los brokers
    getUsuariosFiltrados(10);
    getSuscripciones(1, 999);
  }

  componentDidMount() {
    const {
      getPropiedades,
      getCLientes,
      getProyectos,
      getPropiedadesPaginadas,
      initializeMensaje,
    } = this.props;
    const { page, rowsPerPage, tieneBroker, sortBy } = this.state;
    getPropiedades();
    getCLientes();
    getProyectos();

    getPropiedadesPaginadas("", "", page, rowsPerPage, tieneBroker, sortBy);

    initializeMensaje();
  }

  onClickProductSelected(cell, row, index) {
    this.setState({
      modal: true,
      idProp: row.propiedad,
      Cliente: row.Cliente,
    });
  }

  handleHide = () => {
    this.setState({ modal: false });
  };

  toggleModalCambioEstado = (e, propiedadSeleccionada) => {
    this.setState({
      modalCambioEstado: !this.state.modalCambioEstado,
    });

    if (propiedadSeleccionada != null) {
      this.setState({
        propiedadSeleccionada,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mensaje, getClearAction } = this.props;

    if (mensaje && mensaje.estado === 1) {
      alert("Propiedad Actualizada");
      getClearAction();
    }
  };

  handleChangePage = (event, newPage) => {
    const { filtroEstado, rowsPerPage, sortBy, tieneBroker } = this.state;
    this.setState({
      page: newPage,
    });

    const { getPropiedadesPaginadas } = this.props;
    getPropiedadesPaginadas(
      filtroEstado,
      "",
      newPage + 1,
      rowsPerPage,
      tieneBroker,
      sortBy
    );
  };

  handleChangeRowsPerPage = (event) => {
    const { filtroEstado, tieneBroker, sortBy } = this.state;
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });

    const { getPropiedadesPaginadas } = this.props;
    getPropiedadesPaginadas(
      filtroEstado,
      "",
      1,
      parseInt(event.target.value, 10),
      tieneBroker,
      sortBy
    );
  };

  selectEstadosPropiedad = () => {
    let optionsEstados = [];
    optionsEstados.push(
      <option key="" value="" default>
        - Filtrar por estado -
      </option>
    );
    const estadosNumero = Object.keys(ESTADOS_PROPIEDAD);
    estadosNumero.forEach((k, v) => {
      optionsEstados.push(
        <option key={k} value={k}>
          {ESTADOS_PROPIEDAD[k]}
        </option>
      );
    });
    return optionsEstados;
  };

  selectEstadoBroker = () => {
    let optionsBrokers = [];
    const options = {
      todos: "Todos",
      true: "Con Broker Asignado",
      false: "Sin broker Asignado",
    };

    optionsBrokers.push(
      <option key="" value="" default>
        - Filtrar Segun Asignacion de Broker -
      </option>
    );

    const estadoAsignacion = Object.keys(options);

    estadoAsignacion.forEach((i, v) => {
      optionsBrokers.push(
        <option key={v} value={i}>
          {options[i]}
        </option>
      );
    });

    return optionsBrokers;
  };

  selectSortBy = () => {
    let optionsSortBy = [];
    const options = {
      "createdAtDescending": "Ordenar por fecha de creación descendiente",
      "createdAtAscending": "Ordenar por fecha de creación ascendiente",
      "precioAscending": "Ordenar por precio ascendiente",
      "precioDescending": "Ordenar por precio descendiente",
    };

    const optionsKeys = Object.keys(options);

    optionsKeys.forEach((k, v) => {
      optionsSortBy.push(
        <option key={v} value={k}>
          {options[k]}
        </option>
      );
    });
    return optionsSortBy;
  };

  feedback = () => {
    const { requestPropiedadesPaginadas, requestExcelYapo } = this.props;
    if (requestPropiedadesPaginadas == "LOADING") {
      swal.showLoading();
    } else {
      swal.close();
    }
    
    if (requestExcelYapo == "LOADING") {
      swal.showLoading();
    }
    
  };

  descargarExcelYapo = () => {
    const { getExcelYapo } = this.props;
    getExcelYapo();
  };

  tablePagination = (propiedades) => {
    if (propiedades) {
      return (
        <TablePagination
          count={propiedades.totalResults}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          rowsPerPage={this.state.rowsPerPage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "Cantidad" },
            native: true,
          }}
          labelRowsPerPage={"Resultados por página"}
        />
      );
    } else {
      return <div />;
    }
  };

  handleChangeEstado = (e) => {
    this.setState({
      filtroEstado: e.target.value,
    });

    const { getPropiedadesPaginadas } = this.props;
    getPropiedadesPaginadas(
      e.target.value,
      "",
      1,
      this.state.rowsPerPage,
      this.state.tieneBroker,
      this.state.sortBy
    );
  };

  handleChangeFiltroBroker = (e) => {
    this.setState({
      tieneBroker: e.target.value,
    });
    const { getPropiedadesPaginadas } = this.props;
    const { filtroEstado, sortBy, rowsPerPage } = this.state;
    getPropiedadesPaginadas(
      filtroEstado,
      "",
      1,
      rowsPerPage,
      e.target.value,
      sortBy
    );
  };

  handleChangeSortBy = (e) => {
    this.setState({
      sortBy: e.target.value,
    });
    const { getPropiedadesPaginadas } = this.props;
    const { tieneBroker, rowsPerPage, filtroEstado } = this.state;
    getPropiedadesPaginadas(
      filtroEstado,
      "",
      1,
      rowsPerPage,
      tieneBroker,
      e.target.value
    );
  };

  renderPropiedades = () => {
    const { getPropiedadesPaginadas } = this.props;
    getPropiedadesPaginadas(
      this.state.filtroEstado,
      "",
      this.state.page + 1,
      this.state.rowsPerPage,
      this.state.tieneBroker
    );
  };

  render() {
    const {
      propiedadesPaginadas,
      itemsClientes,
      usuariosFiltrados,
      excelYapo,
      requestExcelYapo,
      dispatch,
    } = this.props;

    if (
      excelYapo != null &&
      excelYapo != undefined &&
      requestExcelYapo != "IDLE" &&
      requestExcelYapo != "ERROR"
    ) {
      const url = window.URL.createObjectURL(new Blob([excelYapo]));
      const link = document.createElement("a");
      link.href = url;
      let fileName = "file";

      link.setAttribute("download", `${fileName}.zip`);
      document.body.appendChild(link);
      link.click();
      dispatch(setExcelYapo(null));
    }

    const estadosOptions = this.selectEstadosPropiedad();
    const estadoAsignacion = this.selectEstadoBroker();
    const sortByOptions = this.selectSortBy();

    return (
      <Container
        fluid
        className=" 
        main-content-container px-4"
      >
        {this.feedback()}
        {/* Page Header */}
        <Row noGutters className=" page-header py-4">
          <Col>
            <PageTitle
              sm="4"
              title="Lista de propiedades"
              subtitle="Propiedades"
              className="text-sm-left"
            />
          </Col>
          <Col md="8">
            <Card>
              <div className="titulo-acciones">Acciones</div>
              <Row>
                <Col md="3" className="acciones-nombre">
                  {" "}
                  <IconButton
                    color="primary"
                    aria-label="Editar"
                    component="span"
                  >
                    <EditIcon />
                  </IconButton>
                  Editar
                </Col>
                <Col md="4" className="acciones-nombre">
                  {" "}
                  <IconButton
                    color="primary"
                    aria-label="Generar tarjeta prop"
                    component="span"
                  >
                    <ArtTrackIcon />
                  </IconButton>
                  Generar tarjeta propiedad
                </Col>
                <Col md="3" className="acciones-nombre">
                  {" "}
                  <IconButton
                    color="primary"
                    aria-label="Cambiar estado"
                    component="span"
                  >
                    <Launch />
                  </IconButton>
                  Cambiar Estado
                </Col>
                <Col className="acciones-nombre">
                  <IconButton
                    color="primary"
                    aria-label="Cambiar estado"
                    component="span"
                  >
                    <FaceIcon />
                  </IconButton>
                  Brokers{" "}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="little-margin-bottom">
          <Col>
            <FormSelect onChange={(e) => this.handleChangeEstado(e)}>
              {estadosOptions}
            </FormSelect>
          </Col>
          <Col>
            <FormSelect onChange={(e) => this.handleChangeFiltroBroker(e)}>
              {estadoAsignacion}
            </FormSelect>
          </Col>

          <Col className="align-self-center">
            <Link
              to={`/addpropiedad`}
            >
              <Button 
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Crear Propiedad
              </Button>
            </Link>
          </Col>
          <Col className="align-self-center">
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={this.descargarExcelYapo}
            >
              Descargar Resultados
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <FormSelect onChange={(e) => this.handleChangeSortBy(e)}>
              {sortByOptions}
            </FormSelect>
          </Col>
        </Row>
        <TablaPropiedades
          propiedades={propiedadesPaginadas}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          tablePagination={() => this.tablePagination(propiedadesPaginadas)}
          clientes={itemsClientes}
          brokers={usuariosFiltrados}
          renderPropiedades={this.renderPropiedades}
        />
      </Container>
    );
  }
}

const formConf = {
  form: "IndexListarPropiedades",
};

const mapStateToProps = (state) => ({
  itemPropiedades: state.app.itemPropiedades,
  itemsClientes: state.app.itemsClientes,
  mensaje: state.app.mensaje,
  propiedadesPaginadas: state.app.propiedadesPaginadas,
  requestPropiedadesPaginadas: state.app.requestPropiedadesPaginadas,
  usuariosFiltrados: state.app.usuariosFiltrados,
  requestUsuariosFiltrados: state.app.requestUsuariosFiltrados,
  requestExcelYapo: state.app.requestExcelYapo,
  excelYapo: state.app.excelYapo
});

const mapDispatchToProps = (dispatch) => ({
  getPropiedades: () => dispatch(fetchGetAllPropiedades()),
  getCLientes: () => dispatch(fetchGetAllClientes()),
  getExcelYapo: () => dispatch(getExcelYapo()),
  postUpdatePropiedad: (obj) => dispatch(fetchPostUpdatePropiedad(obj)),
  getClearAction: () => dispatch(fetchClearAction()),
  deletePropiedad: (id, estado) => dispatch(fetchDeletePropiedad(id, estado)),
  getProyectos: () => dispatch(getAllProyectos()),
  getPropiedadesPaginadas: (estado, idBroker, page, pageSize, tieneBroker, sortBy) =>
    dispatch(
      getPropiedadesPaginadas(estado, idBroker, page, pageSize, tieneBroker, sortBy)
    ),
  initializeMensaje: () => dispatch(initializeMensaje()),
  getUsuariosFiltrados: (tipoCuenta) =>
    dispatch(getUsuariosFiltrados(tipoCuenta)),
  getSuscripciones: (page, pageSize) =>
    dispatch(getSuscripciones(page, pageSize)),
  dispatch: (action) => {
    dispatch(action);
  },
});

IndexListarPropiedades = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexListarPropiedades);
IndexListarPropiedades = reduxForm(formConf)(IndexListarPropiedades);
export default IndexListarPropiedades;
