import React from "react";
import PageTitle from "../../components/common/PageTitle";
import {
    crearPortafolio,
    getPropiedadesPaginadas,
    getPropiedadById,
    getUsuario,
} from "../../action";
import { connect } from "react-redux";
import { Container, Row, Col } from "shards-react";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import { FieldGroup, SelectFieldGroup } from "../../utils/Input";
import "moment/locale/es";
import FileUpload from '../../components/common/FileUpload'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "shards-react";

class FormularioFirmaDigital extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      propiedadSeleccionada: "",
      idPropiedad : "",
      firmantes: [],
      modalAddSignerOpen: false
    };
    const { getPropiedadesPaginadas } = this.props;
    getPropiedadesPaginadas("", "", 1, 999, "todos", "createdAtDescending");
}

  getPropiedadesOptions = (propiedades) => {
    let options = [];
    options.push({ value: "", label: "Seleccione..." });

    propiedades
      .filter((items) => items.planContratado !== null)
      //.filter((items) => items.planContratado.nombre !== "FAST")
      //.filter((items) => items.horarioVisitas !== null)

      .forEach((p) => {
        let direccion = "";
        if (p.tipoPropiedad == "Casa" && p.nombreCalle == null) {
          direccion = `${p.direccionReferencial} ${p.numero}, ${p.comuna}`;
        }

        if (p.tipoPropiedad == "Casa" && p.nombreCalle !== null) {
          direccion = `${p.nombreCalle} ${p.numero}, ${p.comuna}`;
        }

        if (p.tipoPropiedad !== "Casa" && p.nombreCalle == null) {
          direccion = `${p.direccionReferencial} ${p.numero} departamento ${
            p.numeroDepartamento
          }, ${p.comuna}`;
        }
        if (p.tipoPropiedad !== "Casa" && p.nombreCalle !== null) {
          direccion = `${p.nombreCalle} ${p.numero} departamento ${
            p.numeroDepartamento
          }, ${p.comuna}`;
        }
        options.push({
          value: p.id,
          label: direccion,
        });
      });
    return options;
  };

  onChangePropiedad = (e) => {
    this.setState({
      idPropiedad: e.target.value,
      //firmantes: new Array(e.target.value.idUsuario)
    });
    console.log(e.target.value);
    const { getPropiedadById } = this.props;
    getPropiedadById(e.target.value);
  };

  toggleModalAddSigner = () => {
    this.setState({
      modalAddSignerOpen: !this.state.modalAddSignerOpen
    });    
  };

  deleteAllSigners = () => {
    this.setState({
      firmantes: []
    })
  }

  onSubmit = (formData) => {
    const {
      idPropiedad,
      firmantes
    } = this.state;
    const { crearPortafolio } = this.props;
    
    let signers = [];

    firmantes && firmantes.length > 0 && firmantes.forEach(f => {
      let signer = {
        rut: f.rut,
        nombres: f.nombres,
        apellidos: f.apellidos,
        email: f.email,
        telefono: `+56${f.telefono}`,
        urlFirma: "",
        firmado: false,
        documentosIdentidad: [
          {
            tipoDocumentoIdentidad: 0,
            archivo: f.archivoVersoIdentidad,
            asignado: false
          },
          {
            tipoDocumentoIdentidad: 1,
            archivo: f.archivoReversoIdentidad,
            asignado: false
          },
          {
            tipoDocumentoIdentidad: 2,
            archivo: f.archivoCertificadoDocumentoDominio,
            asignado: false
          },
        ],        
      }
      
      signers.push(signer);
    });
    
    let documentos = [];

    if (formData.documentoPoderContratoArriendo) {
      documentos.push({
        tipoDocumentoContrato: 0,
        archivo: formData.documentoPoderContratoArriendo,
        asignado: false,
        urlDocumentoContratoGoFirmex: "",
        urlDocumentoContrato: ""
      });
    }
    if (formData.documentoContratoArriendo) {
      documentos.push({
        tipoDocumentoContrato: 1,
        archivo: formData.documentoContratoArriendo,
        asignado: false,
        urlDocumentoGoFirmex: "",
        urlDocumentoContratoGoFirmex: ""
      });
    }
    if (formData.documentoPromesaCompraVenta) {
      documentos.push({
        tipoDocumentoContrato: 2,
        archivo: formData.documentoPromesaCompraVenta,
        asignado: false,
        urlDocumentoContratoGoFirmex: "",
        urlDocumentoContrato: ""
      });
    }
    if (formData.documentoMandatoAdministracionArriendo) {
      documentos.push({
        tipoDocumentoContrato: 3,
        archivo: formData.documentoMandatoAdministracionArriendo,
        asignado: false,
        urlDocumentoContratoGoFirmex: "",
        urlDocumentoContrato: ""
      });
    }

    const data = {
      idPortafolioGoFirmex: "",
      portafolioCreado: false,
      evaluacionIniciada: false,
      portafolioCompleto: false,
      descripcionEstadoPortafolio: "",
      idPropiedad,
      tipoDocumento: formData.tipoDocumento,
      firmantes: signers,
      documentos,
    }
    console.log(data);
    crearPortafolio(data);
  };

  onDrop = (ready, file, setFieldValueMethod, fieldName) => {
    if (file) {
      file.map(f => {
        if (f) {
          this.fileToBase64(f).then(file64 => {
            /*
            file64 = {
              name: f.name,
              value: file64,
            };
            */
            //esto es para el update
            //values.archivoPDF = [...values.archivoPDF, file64];
            /*
            this.setState({
                file: file64
            });
            */
            setFieldValueMethod(fieldName, file64);
          });   
        }
        return null;
      });
    }    
  }

  fileToBase64 = file => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }    
  };

  addFirmante = (values) => {
    this.setState({
      firmantes: [...this.state.firmantes, values]
    });
    this.toggleModalAddSigner();
  }

  feedback = () => {
    const {
      requestPostPortfolio,
      errorMessage,
      history
    } = this.props;
    if (
      requestPostPortfolio === "LOADING"
    ) {
      Swal.showLoading();
    }
    
    if (requestPostPortfolio === "SUCCESS") {
      Swal.fire({
        title: "Firma digital",
        text: "Se ha iniciado el proceso de firma digital correctamente",
        icon: "success",
        onAfterClose: () => {
          history.push("/firmadigital");
        }
      });
    }

    if (requestPostPortfolio === "ERROR") {
      Swal.fire({
        title: "Firma digital",
        text: errorMessage ? errorMessage : "Ha habido un error en el proceso de firma",
        icon: "error",
      });
    }
  };

  render() {
    const {
      idPropiedad,
      firmantes,
      modalAddSignerOpen
    } = this.state;
    let propiedadesOptions = [];
    const {
      propiedad,
      propiedadesPaginadas,
    } = this.props;

    if (
      propiedadesPaginadas !== null &&
      propiedadesPaginadas.results !== null &&
      propiedadesPaginadas.results.length > 0
    ) {
      propiedadesOptions = this.getPropiedadesOptions(
        propiedadesPaginadas.results //aqui tenemos que solo meter las no fast
      );
    }

    return (
      <Container fluid className="main-content-container px-4">
        {this.feedback()}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Agregar firma digital"
            subtitle="Firma digital"
            className="text-sm-left"
          />
        </Row>
        <div className="container">          
          <Formik
            initialValues={{
              idPropiedad: idPropiedad,
              tipoDocumento: ""
            }}
            validate={validate}
            onSubmit={this.onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <>              
                <Form onSubmit={handleSubmit} className="form-container">
                <div className="seccion w-80">
                  <Row>
                    <Col>
                      <SelectFieldGroup
                        label="Selecciona una propiedad(*)"
                        name="idPropiedad"
                        arrayOps={propiedadesOptions}
                        onChange={this.onChangePropiedad}
                        value={idPropiedad}
                        className="select-visitas"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <SelectFieldGroup
                        label="Tipo de contrato(*)"
                        name="tipoDocumento"
                        arrayOps={[
                          { value: "", label: "Seleccione..." },
                          { value: "0", label: "Contrato Simple" },
                          { value: "1", label: "Mandato Administracion Arriendo" },
                          { value: "2", label: "Promesa Compra Venta" },
                          { value: "3", label: "Contrato Arriendo" },
                        ]}
                        //onChange={this.onChangePropiedad}
                        //value={idPropiedad}
                        className="select-visitas"
                      />
                    </Col>
                  </Row>           
              </div>
              <div className="seccion w-80">
                <Row>
                  <Col>
                    Adjunte al menos un documento
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Poder contrato arriendo
                    <FileUpload 
                      name="documentoPoderContratoArriendo"
                      acceptedFiles={['application/pdf']}
                      onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "documentoPoderContratoArriendo")}
                      sizeLimit={5000000}
                      maxFiles={1}
                    />
                  </Col>
                  <Col>
                    Contrato arriendo
                    <FileUpload 
                      name="documentoContratoArriendo"
                      acceptedFiles={['application/pdf']}
                      onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "documentoContratoArriendo")}
                      sizeLimit={5000000}
                      maxFiles={1}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Promesa compraventa
                    <FileUpload 
                      name="documentoPromesaCompraVenta"
                      acceptedFiles={['application/pdf']}
                      onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "documentoPromesaCompraVenta")}
                      sizeLimit={5000000}
                      maxFiles={1}
                    />
                  </Col>
                  <Col>
                    Mandato Administracion arriendo
                    <FileUpload 
                      name="documentoMandatoAdministracionArriendo"
                      acceptedFiles={['application/pdf']}
                      onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "documentoMandatoAdministracionArriendo")}
                      sizeLimit={5000000}
                      maxFiles={1}
                    />
                  </Col>
                </Row>
              </div>
              <div className="seccion w-80">
                { idPropiedad && (
                  <Container>
                    Seleccionar firmantes
                    { firmantes && firmantes.length > 0 && firmantes.map(f =>
                        <Row className="mb-2">
                          <Col>
                            <ExpansionPanel className={"w-100"}>
                              <ExpansionPanelSummary
                                expandIcon={
                                  <Tooltip title="Ver datos" aria-label="ver detalles">
                                    <IconButton
                                      color="primary"
                                      aria-label="ver detalles"
                                      component="span"
                                    >
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </Tooltip>
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={"w-100"}
                              >
                                <Typography>
                                  {f.nombres} {f.apellidos}
                                </Typography>
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails style={{ width: '100%'}}>
                                <div style={{ width: '100%'}}>
                                  Datos del firmante
                                </div>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Col>
                        </Row>
                    ) }
                    
                    <Button
                      variant="contained"
                      color="primary"
                      //disabled={!(idPropiedad)}
                      onClick={() => this.toggleModalAddSigner()}
                    >
                      Agregar firmante
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      //disabled={!(idPropiedad)}
                      onClick={() => this.deleteAllSigners()}
                    >
                      Eliminar firmantes
                    </Button>

                  </Container>
                  
                )}
              </div>
              <div className="center text-center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!(idPropiedad) || firmantes.length == 0 || !(values.documentoPoderContratoArriendo || values.documentoContratoArriendo || 
                        values.documentoPromesaCompraVenta || values.documentoMandatoAdministracionArriendo)}
                    >
                      Enviar
                    </Button>
                  </div>
            </Form>
            </>
            )}
          </Formik>
        </div>
        <Modal
          fade={false}
          open={this.state.modalAddSignerOpen}
          toggle={this.toggleModalAddSigner}
          size={"lg"}
        >
          <ModalHeader>Agregar firmante</ModalHeader>
          <ModalBody>
          <Formik
            initialValues={{
              nombres: "",
              apellidos: "",
              rut: "",
              telefono: "",
              email: "",
              archivoVersoIdentidad: "",
              archivoReversoIdentidad: "",
              archivoCertificadoDocumentoDominio: ""
            }}
            validate={validateSigner}
            onSubmit={this.onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <>
                <Form onSubmit={handleSubmit} className="form-container">
                  <Row>
                    <Col>
                      <FieldGroup name="nombres" label="Nombres(*)" />
                      <FieldGroup name="apellidos" label="Apellidos(*)" />
                      <Row>
                        <Col>
                          <FieldGroup name="rut" label="Rut(*)" />
                        </Col>
                        <Col>
                          <FieldGroup name="telefono" label="Telefono(*)" />
                        </Col>
                      </Row>
                      <FieldGroup name="email" label="Email(*)" />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      Seleccione archivo verso documento de identidad(*)
                      <FileUpload 
                        name="archivoVersoIdentidad"
                        acceptedFiles={['application/pdf']}
                        onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "archivoVersoIdentidad")}
                        sizeLimit={5000000}
                        maxFiles={1}
                      />
                    </Col>
                    <Col>
                      Seleccione archivo reverso documento de identidad(*)
                      <FileUpload 
                        name="archivoReversoIdentidad"
                        acceptedFiles={['application/pdf']}
                        onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "archivoReversoIdentidad")}
                        sizeLimit={5000000}
                        maxFiles={1}
                      />
                    </Col>
                    <Col>
                      Seleccione archivo certificado documento de dominio(*)
                      <FileUpload 
                        name="archivoCertificadoDocumentoDominio"
                        acceptedFiles={['application/pdf']}
                        onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "archivoCertificadoDocumentoDominio")}
                        sizeLimit={5000000}
                        maxFiles={1}
                      />
                    </Col>
                  </Row>                  
                  <div className="center text-center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.addFirmante(values)}
                      disabled={(Object.keys(errors).length > 0) || (Object.keys(touched).length === 0)}
                    >
                      Agregar firmante
                    </Button>
                  </div>
              
            </Form>
            </>
            )}
          </Formik>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}
const validate = (formValues) => {
  const errors = {};
  /*
  if (!formValues.idPropiedad) {
    errors.idPropiedad = "Seleccione una propiedad";
  }
  */
  if (!formValues.tipoDocumento) {
    errors.tipoDocumento = "Seleccione un tipo de contrato";
  }
  return errors;
};
const validateSigner = (formValues) => {
  const errors = {};
  
  if (!formValues.nombres) {
    errors.nombres = "Ingrese nombres";
  }
  if (!formValues.apellidos) {
    errors.apellidos = "Ingrese apellidos";
  }
  if (!formValues.rut) {
    errors.rut = "Ingrese rut";
  } else if (formValues.rut.length > 10 || formValues.rut.length < 9) {
    errors.rut = "Rut no debe contener puntos e incluir el guión";
  } else if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(formValues.rut)) {
    errors.rut = "Ingrese rut válido";
  } else if (!formValues.rut.includes("-")) {
    errors.rut = "Debe incluir el guión";
  }
  if (!formValues.telefono) {
    errors.telefono = "Ingrese un teléfono";
  } else if (formValues.telefono.length != 9) {
    errors.telefono = "Teléfono debe tener un largo de 9 dígitos";
  }
  if (!formValues.archivoVersoIdentidad) {
    errors.archivoVersoIdentidad = "Adjunte archivo verso de identidad";
  }
  if (!formValues.archivoReversoIdentidad) {
    errors.archivoReversoIdentidad = "Adjunte archivo reverso de identidad";
  }
  if (!formValues.archivoCertificadoDocumentoDominio) {
    errors.archivoCertificadoDocumentoDominio = "Ingrese certificado documento dominio";
  }
  /*
  if (!formValues.archivoCertificadoDocumentoDominio) {
    errors.archivoCertificadoDocumentoDominio = "Adjunte certificado documento de dominio";
  }
  */
  return errors;
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.app.errorMessage,
    propiedadesPaginadas: state.app.propiedadesPaginadas,
    requestPropiedadesPaginadas: state.app.requestPropiedadesPaginadas,
    requestPostPortfolio: state.app.requestPostPortfolio,
    propiedad: state.app.propiedad,
    usuario: state.app.usuario,
  };
};

const mapDispatchToProps = (dispatch) => ({
  //setUsuario: (value) => dispatch(setUsuario(value)),
  //setPropiedadesPaginadas: (value) => dispatch(setPropiedadesPaginadas(value)),
  getPropiedadById: (id) => dispatch(getPropiedadById(id)),
  getPropiedadesPaginadas: (estado, idBroker, page, pageSize, tieneBroker, sortBy) =>
    dispatch(
      getPropiedadesPaginadas(estado, idBroker, page, pageSize, tieneBroker, sortBy)
    ),
  getUsuario: () => dispatch(getUsuario()),
  crearPortafolio: (data) => dispatch(crearPortafolio(data)),
  dispatch: (action) => {
    dispatch(action);
  },
});

FormularioFirmaDigital = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormularioFirmaDigital);

export default FormularioFirmaDigital;
