import React from "react";
import { connect } from "react-redux";
import { fetchValidaToken, restablecerPassword, setReestablecerPassword, setValidateToken } from "../../../src/action";
import "../../assets/Login.css";
import ErrorLabel from "../../utils/ErrorLabel";
import { Formik, Form } from "formik";
import {
  FieldGroup,
} from "../../utils/Input";
import { LoadingModal } from "../../utils/Loading";
import { Row, Col, Button } from "shards-react";
import Swal from "sweetalert2";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoad: false,
      isError: false,
    };    
  }

  onSubmit = (values) => {
    const { restablecerPassword, tokenPassword } = this.props;
    if (tokenPassword) {
      const data = {
        password: values.password,
        email: tokenPassword.usuarioEmail,
      };
      restablecerPassword(data);
    }
  }

  onClickLogin = () => {
    this.props.changeCurrentState("login");
  }

  feedback = () => {
    const { tokenPassword, recover, restablecer, dispatch } = this.props;

    if (tokenPassword != null && (tokenPassword.estado != null && tokenPassword.estado === 0) 
      //&& (recover && (!recover.estado || recover.estado === 0))
    ) {
      console.log("token expirado")
      Swal.fire({
        title: "Token Expirado!",
        text: "Su token expiró, haga click en 'olvidaste tu contraseña' y siga las instrucciones",
        icon: "warning",
        onAfterClose: () => {
          dispatch(setReestablecerPassword(null));
          dispatch(setValidateToken(null));
          this.onClickLogin();
        },
      });
    } else if (restablecer && restablecer.estado === 1) {
      Swal.fire({
        title: "Tu contraseña ha sido modificada con exito",
        text: restablecer.mensaje,
        icon: "success",
        onAfterClose: () => {
          dispatch(setReestablecerPassword(null));
          dispatch(setValidateToken(null));
          this.onClickLogin();
        },
      });
    } else if (restablecer && restablecer.estado === 0) {
      Swal.fire({
        title: "Ups!",
        text: restablecer.mensajes,
        icon: "error",
      });
    } else if (!restablecer) {
      console.log("--")
    }
  }

  render() {
    const { requestCambioContraseña } = this.props;
    return (
      <div>
        { this.feedback() }
        {this.state.isLoad ? <LoadingModal /> : ""}
        <Row>
          <Col>
            <div className="px-2rem mb-4">
              Confirma tu nueva contraseña
            </div>
          </Col>
        </Row>
        <Formik
          initialValues={{
            passwordConfirm: "",
            password: "",
          }}
          validate={validate}
          onSubmit={this.onSubmit}
        >
          {({
            values,
            errors,
            handleSubmit,
            setFieldValue
          }) => (
            <>
              <Form onSubmit={handleSubmit} className="form-container">
                <Row>
                  <Col>
                    <FieldGroup
                      name="password"
                      label="Nueva contraseña"
                      type="password"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FieldGroup
                      name="passwordConfirm"
                      label="Confirmar nueva contraseña"
                      type="password"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="center text-center">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Enviar
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Formik>

        {requestCambioContraseña === "LOADING" && <LoadingModal />}

        {requestCambioContraseña !== "LOADING" && this.state.isError ? (
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <ErrorLabel>Datos invalidos</ErrorLabel>
          </div>
        ) : null}
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (formValues.password === "") {
    errors.password = "Por favor ingrese una nueva contraseña";
  } else if (formValues.password.length < 7) {
    errors.password = "Su contraseña debe tener al menos 6 caracteres";
  } else {

  }

  if (formValues.passwordConfirm === "" || formValues.passwordConfirm !== formValues.password) {
    errors.passwordConfirm = "Las contraseñas ingresadas no coinciden";
  }

  return errors;
}

const mapStateToProps = (state) => ({
  itemUsuario: state.app.itemUsuario,
  loading: state.app.loading,
  tokenPassword: state.app.tokenPassword,
  recover: state.app.recover,
  restablecer: state.app.restablecer,
  requestCambioContraseña: state.app.requestCambioContraseña
});

const mapDispatchToProps = (dispatch) => ({
  restablecerPassword: (data) => dispatch(restablecerPassword(data)),
  fetchValidaToken: (data) => dispatch(fetchValidaToken(data)),
  dispatch: (action) => {
    dispatch(action);
  },
});

ChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);

export default ChangePassword;
