export const UPLOAD_IMAGE_MIME_TYPES = [
  "image/png",
  "image/x-citrix-png",
  "image/x-png",
  "image/jpeg",
  "image/x-citrix-jpeg",
  "image/pjpeg",
];

export const REQUEST_STATE = {
  IDLE: "IDLE",
  SUCCESS: "SUCCESS",
  LOADING: "LOADING",
  ERROR: "ERROR",
};

export const ESTADOS_PROPIEDAD = {
  0: "Plan contratado",
  1: "Propiedad publicada",
  2: "Oferta Vigente",
  3: "Promesa Compraventa Firmada",
  4: "Escritura Compraventa Firmada",
  5: "Propiedad entregada",
  6: "Propiedad no disponible",
};

export const BARRIOS_COMUNAS = {
  "Recoleta": [
    "Cerro Blanco",
    "Patronato",
    "Bellavista",
    "Cementerios",
    "Dorsal",
    "Einstein",
    "Avenida El Salto",
    "Zapadores",
    "Vespucio Norte",
  ],
  "San Miguel": [
    "Ciudad del Niño", 
    "Lo Vial", 
    "El Llano"
  ],
  "Estación Central": [
    "Metro Ecuador",
    "Nogales - San Eugenio",
    "San Alberto Hurtado",
    "Villa Francia",
    "Metro Pajaritos",
    "Metro Las Rejas",
    "Universidad de Santiago",
  ],
  "Peñalolen": [
    "Quilín - Cousiño Macul",
    "Arboretum - Club de Campo",
    "Ictinos",
    "Las Pircas",
    "Las Torres",
    "Metro Los Presidentes - Lo Hermida",
    "Metro Los Orientales",
    "Las Parcelas",
    "Villa San Carlos",
    "Casa Grande",
    "Quebrada de Macul",
    "Esperanza Andina",
    "Comunidad Ecológica",
  ],
  "La Florida": [
    "Plaza Vespucio",
    "La Florida Alto",
    "Vicente Valdés",
    "Metro Mirador",
    "La Florida",
    "San José de La Estrella",
    "Los Quillayes",
    "Trinidad",
    "Lo Cañas - Alto Macul",
    "Rojas Magallanes",
    "Nuevo Amanecer - Chacón Zamora",
    "Metro Santa Julia",
    "Jardines de La Viña",
  ],
  "Ñuñoa": [
    "Plaza Ñuñoa",
    "Metro Ñuñoa",
    "Metro Monseñor Eyzaguirre",
    "Plaza Egaña",
    "Metro Irarrázaval",
    "Estadio Nacional",
    "Diagonal Oriente",
    "Parque Juan XXIII",
    "Parque San Eugenio - Metro Ñuble",
    "Diego de Almagro",
    "Juan Gómez Millas",
    "Villa Frei",
    "Parque Botánico",
    "Amapolas",
    "Villa Los Jardínes - Villa Los Presidentes",
    "Metro Simón Bolivar",
  ],
  "Las Condes": [
    "Barrio El Golf",
    "Sebastián Elcano",
    "Metro Manquehue - Apumanque",
    "Rotonda Atenas",
    "Parque Arauco",
    "Metro Escuela Militar",
    "Alto Las Condes",
    "San Carlos de Apoquindo",
    "Vaticano",
    "Metro Hernando de Magallanes",
    "Centro Financiero",
    "Colón Oriente - Vital Apoquindo",
    "El Remanso",
    "Estoril",
    "Los Dominicos",
    "Mall Sport",
    "Nueva Las Condes",
    "Parque Padre Alberto Hurtado",
    "Quinchamalí",
    "San Damián",
  ],
  "Lo Barnechea": [
    "La Dehesa",
    "Centros Invernales",
    "Puente Nuevo",
    "Los Trapenses",
    "El Huinganal",
    "Valle Escondido",
    "Antigua Lo Barnechea",
    "El Arrayán",
    "Cerro Dieciocho",
    "Camino a Farellones",
    "Valle Nevado",
    "Manquehue Oriente",
    "Jardín La Dehesa",
    "Plaza San Enrique",
  ],
  "Vitacura": [
    "Parque Bicentenario",
    "Pío XI",
    "Tabancura",
    "Santa María de Manquehue",
    "Lo Curro",
    "Santa Teresa de Los Andes",
    "Jardín Del Este",
    "Estadio Croata",
    "Estadio Manquehue",
    "La Llavería",
    "Borde Río - Casa Piedra",
    "Juan XXIII",
    "Luis Pasteur",
    "Villa El Dorado",
    "Nuestra Señora del Rosario",
  ],
  "Macul": [
    "Las Dalias",
    "Metro Camino Agrícola",
    "Metro Las Torres",
    "Villa Macul",
    "Escuela Agrícola",
    "Pedrero - Estadio Monumental",
    "Santa Julia de Macul",
    "Metro Carlos Valdovinos",
    "Metro Quilín",
    "Metro Los Presidentes",
    "Metro Macul",
    "Metro Rodrigo de Araya",
    "Campus San Joaquín",
    "Lomas de Macul",
  ],
  "Colina": [
    "Chicureo", 
    "Centro de Colina", 
    "Casas de Chacabuco", 
    "Colina"
  ],
  "Santiago": [
    "Centro Histórico de Santiago",
    "Santa Isabel",
    "Bulnes",
    "Parque O'Higgins",
    "San Diego",
    "Barrio Yungay",
    "Bogotá - Sierra Bella",
    "Barrio Diez de Julio",
    "Barrio Brasil",
    "Barrio Lastarria",
    "Barrio República",
    "Barrio San Borja",
    "Club Hípico",
    "Ejército - Toesca",
    "Franklin - Biobío",
    "Parque Los Reyes",
    "Parque Almagro",
    "Dieciocho",
    "Meiggs",
  ],
  "Providencia": [
    "Las Lilas",
    "Los Leones",
    "Pedro de Valdivia",
    "Manuel Montt",
    "Inés de Suárez",
    "Campus Oriente",
    "Barrio Italia",
    "Metro Tobalaba - Mall Costanera",
    "Metro Bilbao",
    "Bellavista",
    "Pedro de Valdivia Norte",
    "Plaza Italia",
    "Salvador",
  ],
  "La Reina": [
    "Blest Gana",
    "Metro Príncipe de Gales - Country Club",
    "Metro Simón Bolivar",
    "La Reina Alta",
    "Francisco de Villagra",
    "Carlos Ossandón",
    "Troncos Viejos",
    "Palmas de Mallorca",
    "Pepe Vila",
    "Villa La Reina"
  ],
  "Huechuraba": [
    "Pedro Fontova", 
    "Ciudad Empresarial", 
    "Bosques de la Pirámide",
    "La Pincoya - El Barrero",
    "El Rosal",
    "Los Libertadores"
  ],
  "Independencia": [
    "Hospitales", 
    "Plaza Chacabuco", 
    "Mirador Viejo", 
    "Juan Antonio Ríos",
    "Metro Cal y Canto"
  ],
 "La Cisterna": [
   "Lo Ovalle", 
   "El Parrón", 
   "Metro La Cisterna"
  ],
  "Cerrillos" : [
    "Parque Bicentenario - Metro Cerrillos", 
    "El Mirador", 
    "Plaza Oeste"
  ],
  "Maipú" : [
    "Arauco Maipú", 
    "Avenida Lumen", 
    "Ciudad Satélite", 
    "El Abrazo", 
    "El Descanso", 
    "El Rosal", 
    "Hospital El Carmen de Maipú", 
    "Metro Del Sol", 
    "Metro Las Parcelas", 
    "Metro Santiago Bueras", 
    "Nueva San Martín", 
    "Plaza de Maipú", 
    "Villa Lo Errázuriz", 
    "Zona Industrial de Maipú"
  ],
  "Quilicura" : [
    "San Luis", 
    "Valle Lo Campino", 
    "Norte de Quilicura", 
    "Mall Arauco Quilicura", 
    "Lo Cruzat", 
    "Parque Industrial", 
    "Buanaventura"
  ],
  "Quinta Normal" : [
    "Gruta de Lourdes", 
    "Parque Padre Renato Poblete", 
    "Blanqueado", 
    "Salvador Gutiérrez", 
    "Lo Espinoza", 
    "Garín - Lo Besa"
  ],
  "San Joaquín" : [
    "Rodrigo de Araya", 
    "Carlos Valdovinos", 
    "Metro San Joaquín", 
    "Camino Agrícola", 
    "La Legua", 
    "Metro Pedrero", 
    "La Castrina", 
    "Gozzoli - Mussa"
  ]
};

export const STANDARD_ERROR_MESSAGES = {
  401: "Debe estar logeado para realizar esta acción",
  403: "No posee el tipo de cuenta con los permisos necesarios para realizar esta acción",
  415: "No se permite el formato de archivo subido",
  500: "Error interno del servidor",
  501: "Acción no disponible por el momento",
  503: "Servicio no disponible",
};

export const MESES = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const ANIOS = [
  2021,
  2022
];

export const HORAS = [
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
];