import React from "react";
import PageTitle from "../../../components/common/PageTitle";
import { postNewsletter } from "../../../action";
import { connect } from "react-redux";
import { Container, Row } from "shards-react";
import Swal from 'sweetalert2'
import FormularioNewsletter from "./FormularioNewsletter";

class AgregarEditarNewsletter extends React.Component {
  constructor(...args) {
    super(...args);
  }

  onSubmit = (values) => {
    const { match } = this.props;
    const data = {
      ...values,
      mes: parseInt(values.mes),
      anio: parseInt(values.anio),
    }
    const { postNewsletter } = this.props;

    if (match && match.params && match.params.id) {
      //updateSuscripcion(match.params.id, data);
    } else {
      postNewsletter(data);
    }

  };

  feedback = () => {
    const { requestPostNewsletter, errorMessage, history } = this.props;
    if (requestPostNewsletter === "LOADING") {
      Swal.showLoading();
    }
    if (requestPostNewsletter === "SUCCESS") {
      Swal.fire({
        title: "Newsletter",
        text: "Se ha añadido el newsletter exitosamente",
        type: "success",
        onAfterClose: () => {
          history.push("/newsletter");
        }
      });      
    }

    if (requestPostNewsletter === "ERROR") {
      Swal.fire({
        title: "Error",
        text: errorMessage,
        type: "error",
        showCancelButton: true,
        confirmButtonText: 'Ir a tabla Newsletter',
        cancelButtonText: `Cerrar`
      }).then((result) => {
        if (result.value) {
          history.push("/newsletter");
        }
      });  
    }
  };

  render() {
    const { newsletter } = this.props;
    
    return (
      <Container fluid className="main-content-container px-4">
        { this.feedback() }
        <Row noGutters className="page-header">
          <PageTitle
            sm="4"
            title="Newsletter"
            subtitle="Agregar o editar newsletter"
            className="text-sm-left"
          />
        </Row>
        <FormularioNewsletter values={newsletter} onSubmit={this.onSubmit} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestPostNewsletter: state.app.requestPostNewsletter,
    newsletter: state.app.newsletter,
    errorMessage: state.app.errorMessage
  };
};

const mapDispatchToProps = (dispatch) => ({
  postNewsletter: (data) => dispatch(postNewsletter(data)),
  dispatch: (action) => {
    dispatch(action);
  },
});

AgregarEditarNewsletter = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgregarEditarNewsletter);

export default AgregarEditarNewsletter;
