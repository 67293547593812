import React from "react";
import { connect } from "react-redux";
import { fetchRecuperaPassword, setReestablecerPassword, setValidateToken, setRecoverPassword } from "../../../src/action";
import "../../assets/Login.css";
import ErrorLabel from "../../utils/ErrorLabel";
import { Formik, Form } from "formik";
import {
  FieldGroup,
} from "../../utils/Input";
import { LoadingModal } from "../../utils/Loading";
import { Row, Col, Button } from "shards-react";
import Swal from "sweetalert2";

class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
      isLoad: false,
      isError: false,
    };
    dispatch(setReestablecerPassword(null));
    dispatch(setValidateToken(null));
    dispatch(setRecoverPassword(null));
  }

  onClickLogin = () => {
    this.props.changeCurrentState("login");
  }

  onSubmit = (values) => {
    const { fetchRecuperaPassword } = this.props;
    fetchRecuperaPassword(values);
  }

  feedback = () => {  
    const { recover } = this.props;
    if (recover && recover.data && recover.data.estado === 1) {
      Swal.fire({
        icon: "success",
        title: "Recuperación realizada con éxito",
        text: "Un correo electrónico de recuperación ha sido enviado a su casilla electrónica",
        onAfterClose: () => {
          this.onClickLogin();
        },
      });
    } else if (recover && recover.estado === 0) {
      Swal.fire("Error", recover.mensaje, "error");
    } else {
    }
  }

  render() {
    const { requestRecover } = this.props;
    return (
      <div>
        { this.feedback() }
        {this.state.isLoad ? <LoadingModal /> : ""}
        <Row>
          <Col>
            <div className="px-2rem mb-4">
              Ingresa la dirección de correo electrónico vinculada a tu cuenta y te enviaremos instrucciones para restablecer tu contraseña
            </div>
          </Col>
        </Row>
        <Formik
          initialValues={{
            usuarioEMail: ""
          }}
          validate={validate}
          onSubmit={this.onSubmit}
        >
          {({
            values,
            errors,
            handleSubmit,
            setFieldValue
          }) => (
            <>
              <Form onSubmit={handleSubmit} className="form-container">
                <Row>
                  <Col>
                    <FieldGroup
                      name="usuarioEMail"
                      label="Email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="center text-center">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Enviar
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Formik>

        {requestRecover === "LOADING" && <LoadingModal />}

        {requestRecover !== "LOADING" && this.state.isError ? (
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <ErrorLabel>Datos invalidos</ErrorLabel>
          </div>
        ) : null}
        <div className="text-center">
          <button onClick={this.onClickLogin}>Volver</button>            
        </div>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (formValues.usuarioEMail === "") {
    errors.usuarioEMail = "Por favor ingrese su correo electrónico";
  }
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValues.usuarioEMail) &&
    formValues.usuarioEMail !== ""
  ) {
    errors.usuarioEMail = "Correo electrónico no valido";
  }

  return errors;
}

const mapStateToProps = (state) => ({
  itemUsuario: state.app.itemUsuario,
  requestRecover: state.app.requestRecover,
  recover: state.app.recover
});

const mapDispatchToProps = (dispatch) => ({
  fetchRecuperaPassword: (data) => dispatch(fetchRecuperaPassword(data)),
  dispatch: (action) => {
    dispatch(action);
  },
});

RecoverPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoverPassword);

export default RecoverPassword;
