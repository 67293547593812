import React from "react";
import PageTitle from "../../components/common/PageTitle";
import { getWebinars, setWebinar } from "../../action";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "shards-react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

class IndexWebinar extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      rowsPerPage: 10,
      page: 0,
    };

    const { getWebinars } = this.props;
    getWebinars(1, 10);
  }

  handleChangePage = (event, newPage) => {
    const { rowsPerPage } = this.state;
    this.setState({
      page: newPage,
    });
    const { getWebinars } = this.props;
    getWebinars(newPage+1, rowsPerPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
    const { getWebinars } = this.props;
    getWebinars(1, parseInt(event.target.value, 10));
  };

  feedback = () => {
    const {
      requestGetWebinars
    } = this.props;
    if (
      requestGetWebinars === "LOADING"
    ) {
      Swal.showLoading();
    } else {
      Swal.close();
    }
  };

  render() {
    const { webinars, requestGetWebinars, setWebinar } = this.props;

    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#fff",
        color: "#000",
      },
      body: {
        fontSize: 12,
      },
    }))(TableCell);

    const StyledWideTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#fff",
        color: "#000",
        width: 300,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

    return (
      <Container fluid className="main-content-container px-4">
        {this.feedback()}
        <Row noGutters className="page-header">
          <PageTitle
            sm="4"
            title="Webinar"
            subtitle="Agregar webinar"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col md={9} className={"text-right float-right"} />
          <Col md={3} className={"text-right float-right"}>
            <Link
              style={{
                color: "inherit",
                textDecoration: "inherit",
              }}
              to={`/webinar/add`}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setWebinar(null)}
                color="primary"
              >
                Agregar webinar
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            { requestGetWebinars != "LOADING" && webinars != null && webinars.results != null && webinars.results.length > 0 && (
              <TableContainer component={Paper} className={"hola"}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Título</StyledTableCell>
                    <StyledTableCell align="left">Descripción</StyledTableCell>
                    <StyledWideTableCell align="left">Acciones</StyledWideTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {webinars && webinars.results &&
                    Array.isArray(webinars.results) &&
                    webinars.results.length > 0 &&
                    webinars.results.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="left">{row.titulo}</TableCell>
                          <TableCell align="left">{row.descripcion}</TableCell>
      
                          <TableCell align="left">
                            <Tooltip title="Ir al webinar" aria-label="ver pdf">
                              <a href={row.link} target="_blank"
                              >
                                <IconButton
                                  color="primary"
                                  aria-label="Editar"
                                  component="span"
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              </a>
                            </Tooltip>
                            <Tooltip title="Editar" aria-label="editar">
                            <Link
                          to={{
                            pathname: `/updatewebinar/${row.id}`,
                          }}
                        >
                              <IconButton
                                color="primary"
                                aria-label="Editar"
                                component="span"
                                onClick={() => setWebinar(row)}
                              >
                                <EditIcon />
                              </IconButton>
                              </Link>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                  <TablePagination
          count={webinars.totalResults}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          rowsPerPage={this.state.rowsPerPage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "Cantidad" },
            native: true,
          }}
          labelRowsPerPage={"Resultados por página"}
        />
                    </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            ) }
            { requestGetWebinars == "LOADING" && (<div>Cargando...</div>)}
            { requestGetWebinars != "LOADING" && webinars != null && webinars.results != null && webinars.results.length == 0 && (
              <div>Esta tabla está vacía. Empieza por agregar un newsletter haciendo click en el botón Agregar Newsletter.</div>
            ) }
          </Col>
        </Row> 
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    webinars: state.app.webinars,
    requestGetWebinars: state.app.requestGetWebinars,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getWebinars: (page, pageSize) => dispatch(getWebinars(page, pageSize)),
  setWebinar: (webinar) => dispatch(setWebinar(webinar)),
  dispatch: (action) => {
    dispatch(action);
  },
});

IndexWebinar = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexWebinar);

export default IndexWebinar;
