import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import utilsFunc from '../../utils/utilsFunctions'
import Swal from "sweetalert2";
import { Checkbox } from "@material-ui/core";

class TablaNewsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propiedades: this.props.propiedades,
      page: this.props.page,
      rowsPerPage: this.props.rowsPerPage,
      selectedNewsletters: [],
      selectAll: false
    };
  }

  componentDidMount() {
    const { newsletterResultados } = this.props;
    let newsletterList = [];
    newsletterResultados &&
      Array.isArray(newsletterResultados) &&
      newsletterResultados.length > 0 &&
      newsletterResultados.forEach(n => newsletterList.push({ id: n.id, selected: false })
    );

    this.setState({
      selectedNewsletters: newsletterList
    });
  }

  componentDidUpdate(oldProps, newProps) {
    //new props esta atrasado
    if (this.state.selectedNewsletters != newProps.selectedNewsletters) {
      this.props.updateSelectedNewsletters(this.state.selectedNewsletters);
    }
  }

  selectAll = () => {
    const { selectedNewsletters } = this.state;
    const newList = selectedNewsletters && selectedNewsletters.length > 0 && selectedNewsletters.map(n => ({...n, selected: true}));

    this.setState({
      selectedNewsletters: newList
    });
  }

  unselectAll = () => {
    const { selectedNewsletters } = this.state;
    const newList = selectedNewsletters && selectedNewsletters.length > 0 && selectedNewsletters.map(n => ({...n, selected: false}));

    this.setState({
      selectedNewsletters: newList
    });
  }

  toggleSelect = () => {
    if (!this.state.selectAll) {
      this.selectAll();
      
    } else {
      this.unselectAll();
    }

    this.setState({
      selectAll: !this.state.selectAll
    });    
  }

  deleteNewsletter = (newsletter) => {
    const { getMonthName } = utilsFunc;
    const { deleteNewsletter } = this.props;
    Swal.fire({
      title: `Confirmar eliminación newsletter`,
      text: `¿Está seguro de eliminar newsletter? ${newsletter.comuna}, ${getMonthName(newsletter.mes)} ${newsletter.anio}`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar este newsletter',
      cancelButtonText: `Cancelar`
    }).then((result) => {
      if (result.value) {
        deleteNewsletter(newsletter.id);
      }
    });
  }

  onCheckNewsletter = (id) => {
    let newsletterList = this.state.selectedNewsletters;
    const newList = newsletterList && newsletterList.length > 0 && newsletterList.map(n => {
      if (n.id == id) {
        return {...n, selected: !n.selected}
      }
      return n;
    });

    this.setState({
      selectedNewsletters: newList
    });
  }

  render() {
    const { newsletterResultados } = this.props;
    const { getMonthName } = utilsFunc;
    const { selectedNewsletters } = this.state;
    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#fff",
        color: "#000",
      },
      body: {
        fontSize: 12,
      },
    }))(TableCell);

    const StyledWideTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#fff",
        color: "#000",
        width: 300,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

    const StyledNarrowTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#fff",
        color: "#000",
        width: 50,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);
    
    return (
      <TableContainer component={Paper} className={"hola"}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledNarrowTableCell align="left">
                <Checkbox checked={this.state.selectAll} onChange={this.toggleSelect} />
              </StyledNarrowTableCell>
              <StyledTableCell align="left">Comuna</StyledTableCell>
              <StyledTableCell align="left">Mes</StyledTableCell>
              <StyledTableCell align="left">Anio</StyledTableCell>
              <StyledWideTableCell align="left">Acciones</StyledWideTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newsletterResultados &&
              Array.isArray(newsletterResultados) &&
              newsletterResultados.length > 0 &&
              newsletterResultados.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell align="left">
                      <Checkbox 
                        name={row.id} 
                        checked={selectedNewsletters && selectedNewsletters.length > 0 && selectedNewsletters.filter(s => s.id == row.id).pop().selected} onChange={() => this.onCheckNewsletter(row.id)} />
                    </TableCell>
                    <TableCell align="left">{row.comuna}</TableCell>
                    <TableCell align="left">{getMonthName(row.mes)}
                    </TableCell>
                    <TableCell align="left">{row.anio}</TableCell>

                    <TableCell align="left">
                      <Tooltip title="Ver pdf" aria-label="ver pdf">
                        <a href={row && row.archivoPDF && row.archivoPDF.downloadLink} target="_blank"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Editar"
                            component="span"
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </a>
                      </Tooltip>
                      <Tooltip title="Eliminar" aria-label="eliminar">
                        <IconButton
                          color="primary"
                          aria-label="Editar"
                          component="span"
                          onClick={() => this.deleteNewsletter(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>{this.props.tablePagination()}</TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.app
  };
};

const mapDispatchToProps = (dispatch) => ({
  
});

TablaNewsletter = connect(
  mapStateToProps,
  mapDispatchToProps
)(TablaNewsletter);

export default TablaNewsletter;
