import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
    DateFieldGroup,
  FieldGroup,
  SelectFieldGroup,
} from "../../../utils/Input";
import { Row, Col, Button } from "shards-react";
import { HORAS } from "../../../utils/constants";

export default ({
  values,
  onSubmit,
}) => {  
  const validate = (formValues) => {
    const errors = {};

    if (!formValues.link) {
      errors.link = "Por favor ingrese link";
    }
    if (!formValues.titulo) {
        errors.titulo = "Por favor ingrese título";
    }
    if (!formValues.descripcion) {
        errors.descripcion = "Por favor ingrese descripción";
    }
    /*
    if (!formValues.fechaInicio) {
        errors.fechaInicio = "Por favor ingrese fecha de inicio";
    }
    if (!formValues.fechaFin) {
        errors.fechaFin = "Por favor ingrese fecha fin";
    }
    if (!formValues.horaInicio) {
        errors.horaInicio = "Por favor ingrese hora inicio";
    }
    if (!formValues.horaFin) {
        errors.horaFin = "Por favor ingrese hora fin";
    }
*/
    return errors;
  }

  const getHoras = () => {
    let hrs = [];
    HORAS.map((h) =>
      hrs.push({
        value: h,
        label: h,
      })
    );
    return hrs;
  };

  return (
    <Formik
          initialValues={{
            titulo: values && values.titulo ? values.titulo : "",
            descripcion: values && values.descripcion ? values.descripcion : "",
            link: values && values.link ? values.link : "",
            //fechaInicio: values && values.fechaInicio,
            //horaInicio: values && values.horaInicio,
            //fechaFin: values && values.fechaFin ? values.fechaFin : null,
            //horaFin: values && values.horaFin
          }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => (
            <>
              <Form onSubmit={handleSubmit} className="form-container">
                <Row>
                  <Col>
                    <FieldGroup name="titulo" label="Titulo webinar" />
                  </Col>
                  <Col>
                    <FieldGroup name="descripcion" label="Descripción webinar" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FieldGroup name="link" label="Link webinar" />
                  </Col>
                </Row>
                { /*
                <Row>
                  <Col>
                    <DateFieldGroup
                      name="fechaInicio"
                      label="Fecha inicio webinar"
                      value={values.fechaInicio}
                    />
                  </Col>
                  <Col>
                    <SelectFieldGroup
                      label="Hora inicio webinar"
                      name="horaInicio"
                      arrayOps={getHoras()}
                      value={values.horaInicio}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DateFieldGroup
                      name="fechaFin"
                      label="Fecha fin webinar"
                      values={values.fechaFin}
                    />
                  </Col>
                  <Col>
                    <SelectFieldGroup
                      label="Hora fin webinar"
                      name="horaFin"
                      arrayOps={getHoras()}
                      value={values.horaFin}
                    />
                  </Col>
                </Row>
          */}
                
                <div className="center text-center">
                  <Button variant="contained" color="primary" type="submit">
                    Enviar
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
  );
}