import React from "react";
import PageTitle from "../../components/common/PageTitle";

import { connect } from "react-redux";
import { Container, Row, Col } from "shards-react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Swal from "sweetalert2";
import "moment/locale/es";
import LeadsReferidos from "./LeadsReferidos";
import PosiblesLeads from "./PosiblesLeads";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class IndexLeads extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      currentTab: 0,
    };
    
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      currentTab: newValue
    });
  };

  feedback = () => {
    const { requestGetPosiblesLeads } = this.props;
    if (requestGetPosiblesLeads == "LOADING") {
      Swal.showLoading();
    } else {
      Swal.close();
    }
  }

  render() {
    const { currentTab } = this.state;

    const classes = makeStyles((theme) => ({
      root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
      },
    }));

    return (
      <Container fluid className="main-content-container px-4">
        { this.feedback()}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Leads"
            subtitle="Gestion de leads posibles o referidos"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col sm={12}>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" aria-label="simple tabs example">
                  <Tab label="Posibles leads" {...a11yProps(0)} />
                  <Tab label="Leads referidos" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={currentTab} index={1}>
                <Row>
                  <Col sm={12}>
                    <LeadsReferidos />
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel value={currentTab} index={0}>
                <PosiblesLeads />
              </TabPanel>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestGetPosiblesLeads: state.app.requestGetPosiblesLeads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => {
    dispatch(action);
  },
});

IndexLeads = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexLeads);

export default IndexLeads;
