import React from "react";
import PageTitle from "../../components/common/PageTitle";
import {
    getPortfolios,
    getPropiedadesPaginadas,
    getPropiedadById,
    getUsuario,
    putSignerPortfolio,
    putDocumentsPortfolio,
    putSignerDocumentsPortfolio,
    postRetryPortfolio
} from "../../action";
import { connect } from "react-redux";
import { Container, Row, Col } from "shards-react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import { FieldGroup } from "../../utils/Input";
import "moment/locale/es";
import util from "../../utils/utilsFunctions";
import FileUpload from '../../components/common/FileUpload'
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "shards-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import { Link } from "react-router-dom";
import { Close, Done, Timelapse } from "@material-ui/icons";
class IndexFirmaDigital extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      propiedadSeleccionada: "",
      idPropiedad : "",
      firmante: null,
      modalEditSignerOpen: false,
      modalEditDocumentsOpen: false,
      modalEditSignerDocumentsOpen: false,
      portfolio: null,
      id: null,
      page: 1,
      rowsPerPage: 10
    };
    const { getPortfolios, getPropiedadesPaginadas } = this.props;
    getPortfolios(1, 10);
    getPropiedadesPaginadas("", "", 1, 999, "todos", "createdAtDescending");
  }

  tablePagination = (suscripciones) => {
    if (suscripciones) {
      return (
        <TablePagination
          count={suscripciones.totalResults}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          rowsPerPage={this.state.rowsPerPage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "Cantidad" },
            native: true,
          }}
          labelRowsPerPage={"Resultados por página"}
        />
      );
    } else {
      return <div />;
    }
  };

  handleChangePage = (event, newPage) => {
    const { rowsPerPage } = this.state;
    this.setState({
      page: newPage,
    });
    const { getPortfolios } = this.props;
    getPortfolios(newPage + 1, rowsPerPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
    const { getPortfolios } = this.props;
    getPortfolios(1, parseInt(event.target.value, 10));
  };

  toggleModalEditSigner = (signer, portfolioId) => {
    this.setState({
      modalEditSignerOpen: !this.state.modalEditSignerOpen,
      firmante: signer,
      id: portfolioId
    });    
  };

  toggleModalEditSignerDocuments = (portfolio, signer, portfolioId) => {
    this.setState({
      modalEditSignerDocumentsOpen: !this.state.modalEditSignerDocumentsOpen,
      firmante: signer,
      portfolio,
      id: portfolioId,      
    });
  };

  toggleModalEditDocuments = (portfolio, portfolioId) => {
    this.setState({
      modalEditDocumentsOpen: !this.state.modalEditDocumentsOpen,
      portfolio,
      id: portfolioId,
    });    
  };

  editFirmante = (values) => {
    const { putSignerPortfolio } = this.props;
    putSignerPortfolio(values);
  }

  reiniciarFirmaDigital = (idPortfolio) => {
    const { postRetryPortfolio } = this.props;
    postRetryPortfolio(idPortfolio);
  }

  getDireccionPropiedad = (idPropiedad) => {
    const { propiedadesPaginadas } = this.props;
    let propiedad;

    propiedad = propiedadesPaginadas && propiedadesPaginadas.results 
      && propiedadesPaginadas.results.filter((items) => items.id == idPropiedad);
    console.log(propiedad);
    if (propiedad != null && propiedad.length > 0) {
      propiedad = propiedad[0];
      if (propiedad.tipoPropiedad == "Casa" && propiedad.nombreCalle == null) {
        return `${propiedad.direccionReferencial} ${propiedad.numero}, ${propiedad.comuna}`;
      }
  
      if (propiedad.tipoPropiedad == "Casa" && propiedad.nombreCalle !== null) {
        return `${propiedad.nombreCalle} ${propiedad.numero}, ${propiedad.comuna}`;
      }
  
      if (propiedad.tipoPropiedad !== "Casa" && propiedad.nombreCalle == null) {
        return `${propiedad.direccionReferencial} ${propiedad.numero} departamento ${
          propiedad.numeroDepartamento
        }, ${propiedad.comuna}`;
      }
      if (propiedad.tipoPropiedad !== "Casa" && propiedad.nombreCalle !== null) {
        return `${propiedad.nombreCalle} ${propiedad.numero} departamento ${
          propiedad.numeroDepartamento
        }, ${propiedad.comuna}`;
      }
    }
    
  };

  editDocuments = (values) => {
    let documentos = [];

    if (values.documentoPoderContratoArriendo) {
      documentos.push({
        tipoDocumentoContrato: 0,
        archivo: values.documentoPoderContratoArriendo,
        asignado: false,
        urlDocumentoContratoGoFirmex: "",
        urlDocumentoContrato: ""
      });
    }
    if (values.documentoContratoArriendo) {
      documentos.push({
        tipoDocumentoContrato: 1,
        archivo: values.documentoContratoArriendo,
        asignado: false,
        urlDocumentoGoFirmex: "",
        urlDocumentoContratoGoFirmex: ""
      });
    }
    if (values.documentoPromesaCompraVenta) {
      documentos.push({
        tipoDocumentoContrato: 2,
        archivo: values.documentoPromesaCompraVenta,
        asignado: false,
        urlDocumentoContratoGoFirmex: "",
        urlDocumentoContrato: ""
      });
    }
    if (values.documentoMandatoAdministracionArriendo) {
      documentos.push({
        tipoDocumentoContrato: 3,
        archivo: values.documentoMandatoAdministracionArriendo,
        asignado: false,
        urlDocumentoContratoGoFirmex: "",
        urlDocumentoContrato: ""
      });
    }

    const data = {
      documentos,
      id: values.id
    }
    const { putDocumentsPortfolio } = this.props;
    putDocumentsPortfolio(data);
    console.log(data);
  }

  editSignerDocuments = (formData) => {
    let documentosIdentidad = [];

    if (formData.archivoVersoIdentidad) {
      documentosIdentidad.push({
        tipoDocumentoIdentidad: 0,
        archivo: formData.archivoVersoIdentidad
      });
    }
    if (formData.archivoReversoIdentidad) {
      documentosIdentidad.push({
        tipoDocumentoIdentidad: 1,
        archivo: formData.archivoReversoIdentidad
      });
    }
    if (formData.archivoCertificadoDocumentoDominio) {
      documentosIdentidad.push({
        tipoDocumentoIdentidad: 2,
        archivo: formData.archivoCertificadoDocumentoDominio
      });
    }

    const data = {
      documentosIdentidad,
      id: formData.id,
      rutFirmante: formData.rutFirmante
    }
    const { putSignerDocumentsPortfolio } = this.props;
    putSignerDocumentsPortfolio(data);
    console.log(data);
  }

  onDrop = (ready, file, setFieldValueMethod, fieldName) => {
    if (file) {
      file.map(f => {
        if (f) {
          this.fileToBase64(f).then(file64 => {
            /*
            file64 = {
              name: f.name,
              value: file64,
            };
            */
            //esto es para el update
            //values.archivoPDF = [...values.archivoPDF, file64];
            /*
            this.setState({
                file: file64
            });
            */
            setFieldValueMethod(fieldName, file64);
          });   
        }
        return null;
      });
    }    
  }

  fileToBase64 = file => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }    
  };

  feedback = () => {
    const {
      getPortfolios,
      requestGetPortfolios,
      requestPutSigner,
      requestPutDocumentsPortfolio,
      requestPutSignerDocumentsPortfolio,
      requestPortfolioRetry,
      errorMessage,
    } = this.props;
    
    if (
      requestGetPortfolios === "LOADING" || 
      requestPutSigner === "LOADING" || 
      requestPutDocumentsPortfolio === "LOADING" || 
      requestPutSignerDocumentsPortfolio === "LOADING" ||
      requestPortfolioRetry === "LOADING"
    ) {
      Swal.showLoading();
    }
    
    if (requestGetPortfolios === "SUCCESS" || requestGetPortfolios === "ERROR") {
      Swal.close();
    }

    if (requestPutSigner === "SUCCESS") {
      Swal.fire({
        title: "Firma digital",
        text: "Se han actualizado los datos del firmante exitosamente",
        icon: "success",
        onAfterClose: () => {
          getPortfolios(this.state.page, this.state.rowsPerPage);
          //getPortfolios(1, 10);
          this.toggleModalEditSigner(null, null);
        }
      });
    }
    if (requestPutSigner === "ERROR") {
      Swal.fire({
        title: "Firma digital",
        text: errorMessage ? errorMessage : "Ha habido un error actualizando los datos del firmante",
        icon: "error",
      });
    }

    if (requestPutDocumentsPortfolio === "SUCCESS") {
      Swal.fire({
        title: "Firma digital",
        text: "Se han actualizado los documentos exitosamente",
        icon: "success",
        onAfterClose: () => {
          getPortfolios(this.state.page, this.state.rowsPerPage);
          this.toggleModalEditDocuments(null, null);
        }
      });
    }
    if (requestPutDocumentsPortfolio === "ERROR") {
      Swal.fire({
        title: "Firma digital",
        text: errorMessage ? errorMessage : "Ha habido un error actualizando los documentos",
        icon: "error",
      });
    }

    if (requestPutSignerDocumentsPortfolio === "SUCCESS") {
      Swal.fire({
        title: "Firma digital",
        text: "Se han actualizado los documentos de identidad del firmante exitosamente",
        icon: "success",
        onAfterClose: () => {
          getPortfolios(this.state.page, this.state.rowsPerPage);
          //getPortfolios(1, 10);
          this.toggleModalEditSignerDocuments(null, null);
        }
      });
    }
    if (requestPutSignerDocumentsPortfolio === "ERROR") {
      Swal.fire({
        title: "Firma digital",
        text: errorMessage ? errorMessage : "Ha habido un error actualizando los documentos de identidad del firmante",
        icon: "error",
      });
    }
    
    if (requestPortfolioRetry === "SUCCESS") {
      Swal.fire({
        title: "Firma digital",
        text: "Se iniciado el proceso de firma digital exitosamente",
        icon: "success",
        onAfterClose: () => {
          getPortfolios(this.state.page, this.state.rowsPerPage);
        }
      });
    }
    if (requestPortfolioRetry === "ERROR") {
      Swal.fire({
        title: "Firma digital",
        text: errorMessage ? errorMessage : `Ha habido un error iniciando el proceso de firma digital`,
        icon: "error",
      });
    }
/*
    if (requestGetPortfolios === "ERROR") {
      Swal.fire({
        title: "Firma digital",
        text: errorMessage ? errorMessage : "Ha habido un error cargando las firmas",
        icon: "error",
      });
    } 
    */   
  };

  render() {
    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#fff",
        color: "#000",
      },
      body: {
        fontSize: 12,
      },
    }))(TableCell);

    const StyledWideTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#fff",
        color: "#000",
        width: 300,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

    const {
      idPropiedad,
      firmante,
      id,
      portfolio,
      modalAddSignerOpen
    } = this.state;
    let propiedadesOptions = [];
    const {
      portfolios,
      propiedadesPaginadas,
    } = this.props;

    let documentsAssigned = [];
    //let identityDocumentsAssigned = {};
    let identityDocumentsAssigned = [];

    portfolio && portfolio.documentos && portfolio.documentos.forEach(d => {
      d.asignado && documentsAssigned.push(d.tipoDocumentoContrato);
    });

    firmante && firmante.documentosIdentidad && firmante.documentosIdentidad.forEach(d => {
      d.asignado && identityDocumentsAssigned.push(d.tipoDocumentoIdentidad);
    });

    return (
      <Container fluid className="main-content-container px-4">
        {this.feedback()}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Firma digital"
            subtitle="Firma digital"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col className="align-self-center">
            <Link
              to={`/firmadigital/add`}
            >
              <Button 
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Crear Firma Digital
              </Button>
            </Link>
          </Col>
        </Row>
        <TableContainer component={Paper} className={"hola"}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Id Propiedad</StyledTableCell>
                <StyledTableCell align="left">Firmantes</StyledTableCell>
                <StyledTableCell align="left">Documentos</StyledTableCell>
                <StyledTableCell align="left">Documentos Identidad</StyledTableCell>
                <Tooltip
                  title="Una vez asignado, ya no se pueden modificar los datos de los firmantes"
                  aria-label="Formulario completado"
                >
                  <StyledTableCell align="left">Paso 1 Portafolio asignado</StyledTableCell>
                </Tooltip>
                <Tooltip
                  title="Una vez asignado, ya no se pueden modificar los documentos"
                  aria-label="Formulario completado"
                >
                  <StyledTableCell align="left">Paso 2 Documentos asignados</StyledTableCell>
                </Tooltip>
                <Tooltip
                  title="Una vez asignado, ya no se pueden modificar los documentos de identidad"
                  aria-label="Formulario completado"
                >
                  <StyledTableCell align="left">Paso 3 Documentos identidad asignados</StyledTableCell>
                </Tooltip>
                <Tooltip
                  title="Una vez enviado, ya no se pueden modificar los datos"
                  aria-label="Formulario completado"
                >
                  <StyledTableCell align="left">Paso 4 Enviado a GoFirmex</StyledTableCell>
                </Tooltip>
                <StyledTableCell align="left">Descripcion estado portafolio</StyledTableCell>
                <StyledTableCell align="left">Estado proceso firma digital</StyledTableCell>
                <StyledWideTableCell align="left">Acciones</StyledWideTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {portfolios && portfolios.results &&
                Array.isArray(portfolios.results) &&
                portfolios.results.length > 0 &&
                portfolios.results.map((row) => {
                  let documentosAsignados = true;
                  if (row.documentos.length == 0) {
                    documentosAsignados = false;
                  } else {
                    row.documentos.forEach(x => {
                      if (!x.asignado) {
                        documentosAsignados = false;
                        return;
                      }
                    });
                  }
                  let documentosIdentidadAsignados = true;
                  if (row.firmantes && row.firmantes.length == 0) {
                    documentosIdentidadAsignados = false;
                  } else {
                    row.firmantes.forEach(x => {
                      x.documentosIdentidad.forEach(y => {
                        if (!y.asignado) {
                          documentosIdentidadAsignados = false;
                          return;
                        }
                      });                    
                    });
                  }
                  return (
                    <TableRow key={row.id}>
                      <TableCell align="left">{this.getDireccionPropiedad(row.idPropiedad)}</TableCell>
                      <TableCell align="left">
                        <ul>
                        { row.firmantes && row.firmantes.length > 0 && row.firmantes.map(x => {
                          if (row.portafolioCreado) {
                            return (                              
                              <li>
                                { x.nombres } { x.apellidos }
                              </li>
                            )
                          } else {
                            return (                              
                              <li>
                                <a href="javascript:void(0);" onClick={() => this.toggleModalEditSigner(x, row.id)}>
                                  { x.nombres } { x.apellidos }
                                </a>
                              </li>
                            )
                          }
                        })}
                        </ul>
                      </TableCell>
                      <TableCell align="left">
                      <ul>
                        { row.documentos && row.documentos.map(x => {
                          if (documentosAsignados) {
                            return (                              
                              <li>
                                { util.getTipoDocumentoContrato(x.tipoDocumentoContrato) }
                              </li>
                            )
                          } else {
                            return (                              
                              <li>
                                <a href="javascript:void(0);" onClick={() => this.toggleModalEditDocuments(row, row.id)}>
                                  { util.getTipoDocumentoContrato(x.tipoDocumentoContrato) }
                                </a>
                              </li>
                            )
                          }
                        })}
                        </ul>
                      </TableCell>
                      <TableCell align="left">
                        <ul>
                        { row.firmantes && row.firmantes.map(x => {
                          if (documentosIdentidadAsignados) {
                            return (                              
                              <li>
                                Documentos identidad { x.nombres } { x.apellidos }
                              </li>
                            )
                          } else {
                            return (                              
                              <li>
                                <a href="javascript:void(0);" onClick={() => this.toggleModalEditSignerDocuments(row, x, row.id)}>
                                  Documentos identidad { x.nombres } { x.apellidos }
                                </a>
                              </li>
                            )
                          }
                        })}
                        </ul>
                      </TableCell>
                      <TableCell align="left">
                        {row.portafolioCreado ? (
                          <Tooltip
                          title="Portafolio creado/asignado"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Done />
                          </IconButton>
                        </Tooltip>
                        ) : (
                          <Tooltip
                          title="Portafolio sin asignar"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        { documentosAsignados ? (
                          <Tooltip
                          title="Documentos creados/asignados"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Done />
                          </IconButton>
                        </Tooltip>
                        ) : (
                          <Tooltip
                          title="Documentos sin asignar"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        { documentosIdentidadAsignados ? (
                          <Tooltip
                          title="Documentos identidad creados/asignados"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Done />
                          </IconButton>
                        </Tooltip>
                        ) : (
                          <Tooltip
                          title="Documentos identidad sin asignar"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        { row.evaluacionIniciada ? (
                          <Tooltip
                          title="Evaluación iniciada por GoFirmex"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Done />
                          </IconButton>
                        </Tooltip>
                        ) : (
                          <Tooltip
                          title="Portafolio sin evaluar por GoFirmex"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        { row.descripcionEstadoPortafolio ? row.descripcionEstadoPortafolio : 
                        (row.evaluacionIniciada ? "Procesando por GoFirmex" : "Faltan pasos por completar") }
                      </TableCell>
                      <TableCell align="left">
                        { row.portafolioCompleto ? (
                          <Tooltip
                          title="Proceso finalizado"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Done />
                          </IconButton>
                        </Tooltip>
                        ) : (
                          <Tooltip
                          title="Portafolio sigue en proceso"
                          aria-label="Formulario completado"
                        >
                          <IconButton
                            color="primary"
                            aria-label="Cambiar estado"
                            component="span"
                          >
                            <Timelapse />
                          </IconButton>
                        </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {
                          !(row.portafolioCreado && documentosAsignados && documentosIdentidadAsignados && row.evaluacionIniciada)
                          && (
                            <Tooltip title="Reiniciar" aria-label="reiniciar">
                              <IconButton
                                color="primary"
                                aria-label="Reiniciar"
                                component="span"
                                onClick={() => this.reiniciarFirmaDigital(row.id)}
                              >
                                <ReplayIcon />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                        
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>{this.tablePagination()}</TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <Modal
          fade={false}
          open={this.state.modalEditSignerOpen}
          toggle={this.toggleModalEditSigner}
          size={"lg"}
        >
          <ModalHeader>Agregar firmante</ModalHeader>
          <ModalBody>
          <Formik
            initialValues={{
              id,
              nombres: firmante && firmante.nombres,
              apellidos: firmante && firmante.apellidos,
              rut: firmante && firmante.rut,
              telefono: firmante && firmante.telefono,
              email: firmante && firmante.email
            }}
            validate={validateSigner}
            onSubmit={this.onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <>              
                <Form onSubmit={handleSubmit} className="form-container">
                  <Row>
                    <Col>
                      <FieldGroup name="id" type="hidden" />
                      <FieldGroup name="nombres" label="Nombres" />
                      <FieldGroup name="apellidos" label="Apellidos" />
                      <Row>
                        <Col>
                          <FieldGroup name="rut" readOnly label="Rut" />
                        </Col>
                        <Col>
                          <FieldGroup name="telefono" label="Telefono" />
                        </Col>
                      </Row>
                      <FieldGroup name="email" label="Email" />
                    </Col>
                  </Row>
                  <div className="center text-center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.editFirmante(values)}
                      disabled={(Object.keys(errors).length > 0)}
                    >
                      Editar firmante
                    </Button>
                  </div>
                </Form>
              </>
              )}
            </Formik>
          </ModalBody>
        </Modal>

        <Modal
          fade={false}
          open={this.state.modalEditSignerDocumentsOpen}
          toggle={this.toggleModalEditSignerDocuments}
          size={"lg"}
        >
          <ModalHeader>Agregar documentos identidad firmante</ModalHeader>
          <ModalBody>
          <Formik
            initialValues={{
              id,
              rutFirmante: firmante && firmante.rut,
              archivoVersoIdentidad: null,
              archivoReversoIdentidad: null,
              archivoCertificadoDocumentoDominio: null
            }}
            validate={validateSignerDocuments}
            onSubmit={this.onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <>              
                <Form onSubmit={handleSubmit} className="form-container">
                <Row>
                  <FieldGroup name="id" type="hidden" />
                  <FieldGroup name="rutFirmante" type="hidden" />
                    { !identityDocumentsAssigned.includes(0) && (
                      <Col>
                        Seleccione archivo verso documento de identidad
                        <FileUpload 
                          name="archivoVersoIdentidad"
                          acceptedFiles={['application/pdf']}
                          onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "archivoVersoIdentidad")}
                          sizeLimit={5000000}
                          maxFiles={1}
                        />
                      </Col>
                    ) }
                    { !identityDocumentsAssigned.includes(1) && (
                      <Col>
                        Seleccione archivo reverso documento de identidad
                        <FileUpload 
                          name="archivoReversoIdentidad"
                          acceptedFiles={['application/pdf']}
                          onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "archivoReversoIdentidad")}
                          sizeLimit={5000000}
                          maxFiles={1}
                        />
                      </Col>
                    ) }
                    { !identityDocumentsAssigned.includes(2) && (
                      <Col>
                        Seleccione archivo certificado documento de dominio
                        <FileUpload 
                          name="archivoCertificadoDocumentoDominio"
                          acceptedFiles={['application/pdf']}
                          onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "archivoCertificadoDocumentoDominio")}
                          sizeLimit={5000000}
                          maxFiles={1}
                        />
                      </Col>
                    ) }
                    
                  </Row>         
                  <div className="center text-center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.editSignerDocuments(values)}
                      disabled={
                        (Object.keys(errors).length > 0 || 
                        !(values.archivoVersoIdentidad && values.archivoReversoIdentidad && values.archivoCertificadoDocumentoDominio))}
                    >
                      Adjuntar documentos y reiniciar proceso firma digital
                    </Button>
                  </div>
                </Form>
              </>
              )}
            </Formik>
          </ModalBody>
        </Modal>

        <Modal
          fade={false}
          open={this.state.modalEditDocumentsOpen}
          toggle={this.toggleModalEditDocuments}
          size={"lg"}
        >
          <ModalHeader>Agregar firmante</ModalHeader>
          <ModalBody>
          <Formik
            initialValues={{
              id,
              documentoPoderContratoArriendo: null,
              documentoContratoArriendo: null,
              documentoPromesaCompraVenta: null,
              documentoMandatoAdministracionArriendo: null
            }}
            //validate={validateSigner}
            onSubmit={this.onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <>              
                <Form onSubmit={handleSubmit} className="form-container">
                  { portfolio && portfolio.documentos && (
                    <Container>
                      <Row>
                        <FieldGroup name="id" type="hidden" />
                        
                        {!documentsAssigned.includes(0) && (
                          <Col>
                            Poder contrato arriendo
                            <FileUpload 
                              name="documentoPoderContratoArriendo"
                              acceptedFiles={['application/pdf']}
                              onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "documentoPoderContratoArriendo")}
                              sizeLimit={5000000}
                              maxFiles={1}
                            />
                          </Col>
                        )}
                        {!documentsAssigned.includes(1) && (
                          <Col>
                            Contrato arriendo
                            <FileUpload 
                              name="documentoContratoArriendo"
                              acceptedFiles={['application/pdf']}
                              onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "documentoContratoArriendo")}
                              sizeLimit={5000000}
                              maxFiles={1}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row>
                      {!documentsAssigned.includes(2) && (
                        <Col>
                          Promesa compraventa
                          <FileUpload 
                            name="documentoPromesaCompraVenta"
                            acceptedFiles={['application/pdf']}
                            onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "documentoPromesaCompraVenta")}
                            sizeLimit={5000000}
                            maxFiles={1}
                          />
                        </Col>
                      )}                        
                      {!documentsAssigned.includes(3) && (
                        <Col>
                          Mandato Administracion arriendo
                          <FileUpload 
                            name="documentoMandatoAdministracionArriendo"
                            acceptedFiles={['application/pdf']}
                            onDrop={(ready, file) => this.onDrop(ready, file, setFieldValue, "documentoMandatoAdministracionArriendo")}
                            sizeLimit={5000000}
                            maxFiles={1}
                          />
                        </Col>
                      )}                        
                      </Row>
                      <div className="center text-center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.editDocuments(values)}
                          //disabled={(Object.keys(errors).length > 0)}
                        >
                          Adjuntar documentos y reiniciar proceso firma digital
                        </Button>
                      </div>
                    </Container>
                  )}
                  
                </Form>
              </>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}

const validateSignerDocuments = (formValues) => {
  const errors = {};
  if (!formValues.archivoVersoIdentidad) {
    errors.archivoVersoIdentidad = "Ingrese archivo verso identidad del firmante";
  }
  if (!formValues.archivoReversoIdentidad) {
    errors.archivoReversoIdentidad = "Ingrese archivo reverso identidad del firmante";
  }
  if (!formValues.archivoCertificadoDocumentoDominio) {
    errors.archivoCertificadoDocumentoDominio = "Ingrese certificado documento dominio";
  }
  return errors;
};

const validateSigner = (formValues) => {
  const errors = {};
  if (!formValues.nombres) {
    errors.nombres = "Ingrese nombres";
  }
  if (!formValues.apellidos) {
    errors.apellidos = "Ingrese apellidos";
  }
  if (!formValues.rut) {
    errors.rut = "Ingrese rut";
  } else if (formValues.rut.length > 10 || formValues.rut.length < 9) {
    errors.rut = "Rut no debe contener puntos e incluir el guión válido";
  } else if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(formValues.rut)) {
    errors.rut = "Ingrese rut válido";
  }
  if (!formValues.telefono) {
    errors.telefono = "Ingrese un teléfono";
  } else if (formValues.telefono.length != 12) {
    errors.telefono = "Teléfono debe tener un largo de 12 dígitos (debes incluir +56...)";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.app.errorMessage,
    propiedadesPaginadas: state.app.propiedadesPaginadas,
    portfolios: state.app.portfolios,
    requestPropiedadesPaginadas: state.app.requestPropiedadesPaginadas,
    requestGetPortfolios: state.app.requestGetPortfolios,
    requestPostPortfolio: state.app.requestPostPortfolio,
    propiedad: state.app.propiedad,
    usuario: state.app.usuario,
    requestPutSigner: state.app.requestPutSigner,
    requestPutDocumentsPortfolio: state.app.requestPutDocumentsPortfolio,
    requestPutSignerDocumentsPortfolio: state.app.requestPutSignerDocumentsPortfolio,
    requestPortfolioRetry: state.app.requestPortfolioRetry
  };
};

const mapDispatchToProps = (dispatch) => ({
  //setUsuario: (value) => dispatch(setUsuario(value)),
  //setPropiedadesPaginadas: (value) => dispatch(setPropiedadesPaginadas(value)),
  getPropiedadById: (id) => dispatch(getPropiedadById(id)),
  getPropiedadesPaginadas: (estado, idBroker, page, pageSize, tieneBroker, sortBy) =>
    dispatch(
      getPropiedadesPaginadas(estado, idBroker, page, pageSize, tieneBroker, sortBy)
    ),
  getUsuario: () => dispatch(getUsuario()),
  getPortfolios: (page, pageSize) => dispatch(getPortfolios(page, pageSize)),
  putSignerPortfolio: (data) => dispatch(putSignerPortfolio(data)),
  putDocumentsPortfolio: (data) => dispatch(putDocumentsPortfolio(data)),
  putSignerDocumentsPortfolio: (data) => dispatch(putSignerDocumentsPortfolio(data)),
  postRetryPortfolio: (idPortfolio) => dispatch(postRetryPortfolio(idPortfolio)),
  dispatch: (action) => {
    dispatch(action);
  },
});

IndexFirmaDigital = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexFirmaDigital);

export default IndexFirmaDigital;
