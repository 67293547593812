import React from "react";
import PageTitle from "../../../components/common/PageTitle";
import { postWebinar, putWebinar } from "../../../action";
import { connect } from "react-redux";
import { Container, Row } from "shards-react";
import Swal from 'sweetalert2'
import FormularioWebinar from './FormularioWebinar';

class AgregarEditarWebinar extends React.Component {
  constructor(...args) {
    super(...args);
  }

  onSubmit = (values) => {
    const { match } = this.props;

    const { postWebinar, putWebinar } = this.props;

    if (match && match.params && match.params.id) {
        putWebinar(match.params.id, values);
    } else {
        postWebinar(values);
    }

  };

  feedback = () => {
    const { requestPutWebinar, requestPostWebinar, history } = this.props;
    if (requestPutWebinar === "LOADING" || requestPostWebinar === "LOADING") {
      Swal.showLoading();
    }
    if (requestPutWebinar === "SUCCESS") {
      Swal.fire({
        title: "Webinar editado",
        text: "Se ha editado el webinar exitosamente",
        type: "success",
        onAfterClose: () => {
          history.push("/webinar");
        }
      });
    }
    if (requestPostWebinar === "SUCCESS") {
      Swal.fire({
        title: "Webinar",
        text: "Se ha añadido el webinar exitosamente",
        type: "success",
        onAfterClose: () => {
          history.push("/webinar");
        }
      });
    }

    if (requestPostWebinar === "ERROR") {
      Swal.fire("Error", "No se ha podido añadir la suscripción", "error");
    }

    if (requestPutWebinar === "ERROR") {
      Swal.fire("Error", "No se ha podido editar la suscripción", "error");
    }
  };

  render() {
    const { webinar } = this.props;
    
    return (
      <Container fluid className="main-content-container px-4">
        { this.feedback() }
        <Row noGutters className="page-header">
          <PageTitle
            sm="4"
            title="Webinar"
            subtitle="Webinar"
            className="text-sm-left"
          />
        </Row>
        <FormularioWebinar values={webinar} onSubmit={this.onSubmit} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestPostWebinar: state.app.requestPostWebinar,
    webinar: state.app.webinar,
    requestPutWebinar: state.app.requestPutWebinar
  };
};

const mapDispatchToProps = (dispatch) => ({
    postWebinar: (data) => dispatch(postWebinar(data)),
  putWebinar: (id, suscripcion) => dispatch(putWebinar(id, suscripcion)),
  dispatch: (action) => {
    dispatch(action);
  },
});

AgregarEditarWebinar = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgregarEditarWebinar);

export default AgregarEditarWebinar;
