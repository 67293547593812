import React from "react";
import { connect } from "react-redux";
import { fetchPostLoginUser, fetchValidaToken } from "../../../src/action";
import "../../assets/Login.css";
import icon from "../../utils/images";
import ErrorLabel from "../../utils/ErrorLabel";
import { LoadingModal } from "../../utils/Loading";
import Login from "./Login";
import RecoverPassword from "./RecoverPassword";
import utilsFunc from '../../utils/utilsFunctions'
import ChangePassword from "./ChangePassword";

class IndexSignIn extends React.Component {
  constructor(props) {
    super(props);
    const id = utilsFunc.getUrlParameter("id");

    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
      isLoad: false,
      isError: false,
      current: id ? "change" : "login"
    };
  }

  componentDidMount = () => {
    const { fetchValidaToken } =
      this.props;

    const id = utilsFunc.getUrlParameter("id");
    if (id) {
      fetchValidaToken(id);
    } 
  };

  componentDidUpdate = () => {
    const {
      fetchValidaToken,
      tokenPassword,
    } = this.props;

    const id = utilsFunc.getUrlParameter("id");
    if (id && tokenPassword && tokenPassword.length > 0) {
      fetchValidaToken(id);
    }
  }

  changeCurrentState = (newCurrentValue) => {
    this.setState({
      current: newCurrentValue
    });
  }

  render() {
    const { loading } = this.props;
    return (
      <div className="Login">
        {this.state.isLoad ? <LoadingModal /> : ""}

        <div className="wrapper fadeInDown">
          <div id="formContent">
            <div className="fadeIn first">
              <div className="logo">
                <img src={icon.logoColor} alt="" />
              </div>
            </div>
            { this.state.current == "login" && (
              <Login changeCurrentState={this.changeCurrentState} />
            )}

            { this.state.current == "recover" && (
              <RecoverPassword changeCurrentState={this.changeCurrentState} />
            )}

            { this.state.current == "change" && (
              <ChangePassword changeCurrentState={this.changeCurrentState} />
            )}

            {loading && <LoadingModal />}

            {!loading && this.state.isError ? (
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <ErrorLabel>Datos invalidos</ErrorLabel>
              </div>
            ) : null}
          </div>
        </div>
        
      </div>
      
    );
  }
}


const mapStateToProps = (state) => ({
  itemUsuario: state.app.itemUsuario,
  loading: state.app.loading,
  recover: state.app.recover,
  tokenPassword: state.app.tokenPassword
});

const mapDispatchToProps = (dispatch) => ({
  postLoginUser: (pass, mail) => dispatch(fetchPostLoginUser(pass, mail)),
  fetchValidaToken: (id) => dispatch(fetchValidaToken(id)),
});

IndexSignIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexSignIn);

export default IndexSignIn;
