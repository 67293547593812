import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { fetchPostLoginUser } from "../../../src/action";
import "../../assets/Login.css";
import { ControlLabel, FormGroup, FormControl, Button } from "react-bootstrap";
import ErrorLabel from "../../utils/ErrorLabel";
import { LoadingModal } from "../../utils/Loading";
import Swal from "sweetalert2";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
      isLoad: false,
      isError: false,
    };
    
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { itemUsuario } = nextProps;
    const estado = itemUsuario && itemUsuario[0] ? itemUsuario[0].Estado : null;
    const newState = {};

    if (estado === 0 && !prevState.isError) {
      newState.isError = true;
    }

    return newState;
  }

  login = (event) => {
    event.preventDefault();
    this.setState({ isLoad: true }, () => {
      const { postLoginUser } = this.props;
      if (this.state.password !== "" && this.state.email !== "") {
        postLoginUser(this.state.password, this.state.email);
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { itemUsuario, history, requestLogin } = this.props;
    if (requestLogin === "SUCCESS" && itemUsuario != null) {
      const { history } = this.props;
      if (history != null) {
        setTimeout(function(){ 
          history.push("/home");
        }, 1100);
      }

    } else {
      if (this.state.isLoad !== prevState.isLoad) {
        this.setState({ isLoad: false });
      }
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  onClickForgotPassword = () => {    
    this.props.changeCurrentState("recover");
  }

  feedback = () => {
    const { requestLogin, errorMessage } = this.props;
    if (requestLogin === "ERROR" ) {
      Swal.fire("Error", errorMessage, "error");
    }  
  }

  render() {
    const { loading } = this.props;
    return (
      <div>
        { this.feedback() }
        <form onSubmit={this.login}>
          <FormGroup controlId="email">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Button
            className="w-100"
            bsSize="large"
            bsStyle="primary"
            type="submit"
          >
            Ingresar
          </Button>
        </form>

        {loading && <LoadingModal />}

        {!loading && this.state.isError ? (
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <ErrorLabel>Datos invalidos</ErrorLabel>
          </div>
        ) : null}

        <div className="text-center">
          <button onClick={this.onClickForgotPassword}>Olvidé mi contraseña</button>            
        </div>
      </div>
    );
  }
}

const formConf = {
  form: "my-form",
};

const mapStateToProps = (state) => ({
  itemUsuario: state.app.itemUsuario,
  loading: state.app.loading,
  requestLogin: state.app.requestLogin,
  errorMessage: state.app.errorMessage
});

const mapDispatchToProps = (dispatch) => ({
  postLoginUser: (pass, mail) => dispatch(fetchPostLoginUser(pass, mail)),
});

Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
Login = reduxForm(formConf)(Login);

export default withRouter(Login);
