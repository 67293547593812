import React from "react";
import PageTitle from "../../components/common/PageTitle";
import {
  deleteNewsletterById,
  getAllNewsletters,
  setNewsletter,
  sendEmailsNewsletter
} from "../../action";
import { connect } from "react-redux";
import { Container, Row, Col } from "shards-react";
import AddIcon from "@material-ui/icons/Add";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import TablaNewsletter from "./TablaNewsletter";
import TablePagination from "@material-ui/core/TablePagination";
import { Link } from "react-router-dom";

class IndexNewsletter extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      totalResults: 0,
      checkedNewsletters: []
    };

    const {
      getAllNewsletters,
    } = this.props;
    getAllNewsletters("0", "0", "", 10, 1);
  }

  tablePagination = (newsletters) => {
    if (newsletters) {
      return (
        <TablePagination
          count={newsletters.totalResults}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          rowsPerPage={this.state.rowsPerPage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "Cantidad" },
            native: true,
          }}
          labelRowsPerPage={"Resultados por página"}
        />
      );
    } else {
      return <div />;
    }
  };
  
  handleChangePage = (event, newPage) => {
    const { rowsPerPage } = this.state;
    this.setState({
      page: newPage,
    });
    const { getAllNewsletters } = this.props;
    getAllNewsletters(0, 0, "", rowsPerPage, newPage + 1);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
    const { getAllNewsletters } = this.props;
    getAllNewsletters(0, 0, "", parseInt(event.target.value, 10), 1);
  };

  setSelectedNewsletters = (val) => {
    this.setState({
      checkedNewsletters: val
    });
  }

  sendEmails = async () => {
    const { sendEmailsNewsletter } = this.props;
    const { checkedNewsletters } = this.state;
    Swal.fire({
      title: `Confirmar envio emails`,
      text: `¿Está seguro de enviar emails de los newsletters seleccionados?`,
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: `Cancelar`
    }).then((result) => {
      if (result.value) {
        let selectedNewsletters = [];
        checkedNewsletters && checkedNewsletters.length > 0 && checkedNewsletters.forEach(c => c.selected && selectedNewsletters.push(c.id));

        if (selectedNewsletters.length > 0) {
          const data = {
            idList: selectedNewsletters
          }
          sendEmailsNewsletter(data);
        }
      }  
    });
  }

  deleteNewsletter = (id) => {
    const { deleteNewsletterById } = this.props;
    deleteNewsletterById(id);
  }
  
  feedback = () => {
    const {
      requestGetAllNewsletters,
      requestDeleteNewsletterById,
      requestSendEmailsNewsletter,
      errorMessage,
      getAllNewsletters
    } = this.props;
    if (requestGetAllNewsletters == "LOADING" || requestDeleteNewsletterById == "LOADING" || requestSendEmailsNewsletter == "LOADING") {
      Swal.showLoading();
    } else {
      if (requestGetAllNewsletters == "SUCCESS") {
        Swal.close()
      }
      if (requestDeleteNewsletterById == "SUCCESS") {
        Swal.fire({
          title: "Eliminado",
          text: "Se ha eliminado el newsletter exitosamente",
          icon: "success",
          onAfterClose: () => {
            getAllNewsletters(this.state.page, this.state.rowsPerPage);
          }
        }); 
      }
  
      if (requestSendEmailsNewsletter == "SUCCESS") {
        Swal.fire({
          title: "Correos enviados",
          text: "Se han enviado los correos exitosamente",
          icon: "success"
        }); 
      }
  
      if (requestGetAllNewsletters === "ERROR" || requestDeleteNewsletterById === "ERROR") {
        Swal.fire("Error", errorMessage, "error");
      }    
    }
    
  };

  render() {
    const { newsletters, requestGetAllNewsletters } = this.props;
    const { checkedNewsletters } = this.state;
    let isAnyNewsletterChecked = false;
    checkedNewsletters && checkedNewsletters.length > 0 && checkedNewsletters.forEach(c => {
      if (c.selected) {
        isAnyNewsletterChecked = true;
        return;
      }
    });
    
    return (
      <Container fluid className="main-content-container px-4">
        {this.feedback()}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Newsletter"
            subtitle="Gestiona los newsletter"
            className="text-sm-left"
          />
        </Row>
        <Row className="mb-3">
          <Col md={6} />
          <Col md={3} className={"text-right float-right"}>
            <Button
              variant="contained"
              startIcon={<EmailIcon />}
              onClick={() => this.sendEmails()}
              color="primary"
              disabled={!isAnyNewsletterChecked}
            >
              Enviar emails
            </Button>
          </Col>
          <Col md={3} className={"text-right float-right"}>
            <Link
              style={{
                color: "inherit",
                textDecoration: "inherit",
              }}
              to={`/newsletter/add`}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={setNewsletter(null)}
                color="primary"
              >
                Agregar Newsletter
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            { requestGetAllNewsletters != "LOADING" && newsletters != null && newsletters.results != null && newsletters.results.length > 0 && (
              <TablaNewsletter
                newsletterResultados={newsletters && newsletters.results}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                tablePagination={() => this.tablePagination(newsletters)}
                feedback={this.tablaFeedback}
                deleteNewsletter={this.deleteNewsletter}
                updateSelectedNewsletters={(val) => this.setSelectedNewsletters(val)}
              />
            ) }
            { requestGetAllNewsletters == "LOADING" && (<div>Cargando...</div>)}
            { requestGetAllNewsletters != "LOADING" && newsletters != null && newsletters.results != null && newsletters.results.length == 0 && (
              <div>Esta tabla está vacía. Empieza por agregar un newsletter haciendo click en el botón Agregar Newsletter.</div>
            ) }
          </Col>
        </Row> 
        
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsletters: state.app.newsletters,
    errorMessage: state.app.errorMessage,
    newsletter: state.app.newsletter,
    requestGetAllNewsletters: state.app.requestGetAllNewsletters,
    requestDeleteNewsletterById: state.app.requestDeleteNewsletterById,
    requestSendEmailsNewsletter: state.app.requestSendEmailsNewsletter
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllNewsletters: (anio, mes, comuna, pageSize, page) => dispatch(getAllNewsletters(anio, mes, comuna, pageSize, page)),
  setNewsletter: (newsletter) => dispatch(setNewsletter(newsletter)),
  deleteNewsletterById: (id) => dispatch(deleteNewsletterById(id)),
  sendEmailsNewsletter: (data) => dispatch(sendEmailsNewsletter(data))
});

IndexNewsletter = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexNewsletter);

export default IndexNewsletter;
