import React from "react";
import {
  uploadPosiblesLeads,
  cambiarContactadoPosibleLead,
  getPosiblesLeads,
  updatePosibleLead,
  getUsuariosFiltrados
} from "../../action";
import { connect } from "react-redux";
import {
  Collapse,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormTextarea,
  FormInput,
  FormGroup,
  FormSelect
} from "shards-react";
import Swal from "sweetalert2";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FileUpload from "../../components/common/FileUpload";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import {
  SelectFieldGroup,
  FieldGroup
} from "../../utils/Input";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { CalendarToday, FilterList, OpenInNew } from "@material-ui/icons";
import { Formik, Form } from "formik";
import FaceIcon from "@material-ui/icons/Face";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

class PosiblesLeads extends React.Component {
  constructor(...args) {
    super(...args);
    let user = JSON.parse(localStorage.getItem("user"));
    let idBrokerFilter = user && user.tipoCuenta === 10 ? user.userId : "";

    this.state = {
      rowsPerPage: 10,
      page: 0,
      collapse: false,
      leadSeleccionado: null,
      isDetailModalOpen: false,
      isEditCommentModalOpen: false,
      isEditPhoneNumberModalOpen: false,
      isAssignBrokerModalOpen: false,
      comment: "",
      phoneNumber: "",
      isFilterOpen: false,
      filtroNombre: "",
      filtroContactado: "",
      filtroDiasUltimoContacto: "",
      filtroBroker: idBrokerFilter,
      sortBy: "createdAtDescending",
      nuevoBroker: "",
      brokerLead: null
    };

    const { getPosiblesLeads, getUsuariosFiltrados } = this.props;
    getPosiblesLeads("", "", "", idBrokerFilter, "createdAtDescending", 1, 10);
    getUsuariosFiltrados(10);
  }

  toggleUpload = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  showDetailModal = (lead) => {
    this.setState({
      isDetailModalOpen: true,
      leadSeleccionado: lead
    })
  }

  toggleDetailModal = (lead) => {
    const { usuariosFiltrados } = this.props;
    let brokerLead = null;

    if (usuariosFiltrados != null && lead != null && lead.idBroker != null) {
      brokerLead = usuariosFiltrados
        .filter((b) => b.id == lead.idBroker)
        .pop();
      this.setState({        
        brokerLead
      });
    }
    this.setState({
      leadSeleccionado: lead,
      isDetailModalOpen: !this.state.isDetailModalOpen
    });
  }

  showEditCommentModal = (lead) => {
    this.setState({
      comment: lead.comentario,
      isEditCommentModalOpen: true,
      leadSeleccionado: lead
    });
  }

  hideEditCommentModal = () => {
    this.setState({
      isEditCommentModalOpen: false
    });
  }

  toggleEditCommentModal = () => {
    this.setState({
      isEditCommentModalOpen: !this.state.isEditCommentModalOpen,
    });
  }

  showEditPhoneNumberModal = (lead) => {
    this.setState({
      phoneNumber: lead.telefono,
      isEditPhoneNumberModalOpen: true,
      leadSeleccionado: lead
    });
  }

  hideEditPhoneNumberModal = () => {
    this.setState({
      isEditPhoneNumberModalOpen: false
    });
  }

  toggleEditPhoneNumberModal = () => {
    this.setState({
      isEditPhoneNumberModalOpen: !this.state.isEditPhoneNumberModalOpen,
    });
  }

  showModalAsignacionBroker = (lead) => {
    this.setState({
      isEditPhoneNumberModalOpen: true,
      leadSeleccionado: lead
    });
  }

  toggleModalAsignacionBroker = (row) => {
    const { usuariosFiltrados } = this.props;
    let brokerLead = null;

    if (row != null) {
      if (usuariosFiltrados != null && row.idBroker != null) {
        brokerLead = usuariosFiltrados
          .filter((b) => b.id == row.idBroker)
          .pop();
      }

      this.setState({
        leadSeleccionado: row,
        nuevoBroker: "",
        brokerLead,
      });
    }

    this.setState({
      isAssignBrokerModalOpen: !this.state.isAssignBrokerModalOpen,
    });
  }

  hideModalAsignacionBroker = () => {
    this.setState({
      isAssignBrokerModalOpen: false
    });
  }

  handleCommentChange = (e) => {
    this.setState({
      comment: e.target.value
    });
  }

  handlePhoneNumberChange = (e) => {
    this.setState({
      phoneNumber: e.target.value
    });
  }

  updateComment = () => {
    const { leadSeleccionado, comment } = this.state;
    const data = {
      telefono: leadSeleccionado.telefono,
      idBroker: leadSeleccionado.idBroker,
      comentario: comment
    }

    this.updateLead(leadSeleccionado.id, data);
  }

  updatePhoneNumber = () => {
    const { leadSeleccionado, phoneNumber } = this.state;
    const data = {
      telefono: phoneNumber,
      idBroker: leadSeleccionado.idBroker,
      comentario: leadSeleccionado.comentario
    }

    this.updateLead(leadSeleccionado.id, data);
  }

  updateLead = (id, data) => {
    const { updatePosibleLead } = this.props;
    updatePosibleLead(id, data);
  }

  onDrop = (ready, file) => {
    let message = "Archivo válido";
    if (!ready) {
      message = "Archivo inválido. Solo se acepta formato .xlsx y archivos con un peso igual o menor a 5 mb";
    }
    this.setState({
      ready,
      message,
      hasInteracted: true,
      file
    })
  }

  handleClick = (id) => {
    const { cambiarContactadoPosibleLead } = this.props;
    cambiarContactadoPosibleLead(id);
  }

  asignarBroker = () => {
    const { leadSeleccionado, nuevoBroker } = this.state;
    const data = {
      idBroker: nuevoBroker,
      telefono: leadSeleccionado.telefono,
      comentario: leadSeleccionado.comentario
    }

    this.updateLead(leadSeleccionado.id, data);
  }

  onSubmitFile = (value) => {
    if (value) {
      const { uploadPosiblesLeads } = this.props;
      let formData = new FormData();
      formData.append("file", this.state.file[0]);
      uploadPosiblesLeads(formData);
    }
  };

  handleCambioBroker = (e) => {
    this.setState({
      nuevoBroker: e.target.value,
    });
  };

  handleChangePage = (event, newPage) => {
    const { rowsPerPage, filtroNombre, filtroContactado, filtroDiasUltimoContacto, filtroBroker, sortBy } = this.state;
    this.setState({
      page: newPage,
    });
    const { getPosiblesLeads } = this.props;
    getPosiblesLeads(filtroNombre, filtroContactado, filtroDiasUltimoContacto, filtroBroker, sortBy, newPage + 1, rowsPerPage);
  };

  handleChangeRowsPerPage = (event) => {
    const { filtroNombre, filtroContactado, filtroDiasUltimoContacto, filtroBroker, sortBy } = this.state;
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
    const { getPosiblesLeads } = this.props;
    getPosiblesLeads(filtroNombre, filtroContactado, filtroDiasUltimoContacto, filtroBroker, sortBy, 1, parseInt(event.target.value, 10));
  };

  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen
    });
  }
  
  onClickAplicarFiltro = (values) => {
    const { getPosiblesLeads } = this.props;
    this.setState({
      filtroNombre: values.nombre,
      filtroContactado: values.contactado,
      filtroDiasUltimoContacto: values.diasDesdeUltimoContacto,
      filtroBroker: values.idBroker,
      sortBy: values.sortBy,
    });
    getPosiblesLeads(values.nombre, values.contactado, values.diasDesdeUltimoContacto, values.idBroker, values.sortBy, this.state.page+1, this.state.rowsPerPage);
  }

  selectBrokers = () => {
    let brokersOptions = [];
    brokersOptions.push(
      <option key="" value="">
        - Cambiar broker -
      </option>
    );
    const { leadSeleccionado } = this.state;
    const { usuariosFiltrados } = this.props;
    if (usuariosFiltrados != null) {
      usuariosFiltrados.forEach((k, v) => {
        if (
          leadSeleccionado != undefined &&
          leadSeleccionado.idBroker != k.id
        ) {
          brokersOptions.push(
            <option key={k.id} value={k.id}>
              {k.nombres} {k.apellidos}
            </option>
          );
        }
      });
    }
    return brokersOptions;
  };

  getTextoCtdadDias = (dias) => {
    let textoCantidadDias = "";
    switch(dias) {
      case 0:
        textoCantidadDias = `hace menos de un día`;;
        break;
      case 1:
        textoCantidadDias = `hace 1 día`;
        break;
      default:
        textoCantidadDias = `hace ${dias} días`;
    }

    return textoCantidadDias;
  }
  
  feedback = () => {
    const { requestUploadPosiblesLeads, requestGetPosiblesLeads, requestCambiarContactadoPosibleLead, 
      requestUpdatePosibleLead, errorMessage, getPosiblesLeads } = this.props;
    const { rowsPerPage, page, filtroNombre, filtroContactado, filtroDiasUltimoContacto, filtroBroker, sortBy } = this.state;

    if (requestCambiarContactadoPosibleLead === "SUCCESS") {
      Swal.fire({
        icon: "success",
        title: "Cambio exitoso",
        text: "Se ha cambiado el estado de contacto del lead con éxito",
        onAfterClose: () => {
          getPosiblesLeads(filtroNombre, filtroContactado, filtroDiasUltimoContacto, filtroBroker, sortBy, page, rowsPerPage);
        },
      });
    }

    if (requestUploadPosiblesLeads === "SUCCESS") {
      Swal.fire({
        icon: "success",
        title: "Cambio exitoso",
        text: "Se ha subido el archivo con éxito",
        onAfterClose: () => {
          getPosiblesLeads(filtroNombre, filtroContactado, filtroDiasUltimoContacto, filtroBroker, sortBy, page, rowsPerPage);
        },
      });
    }

    if (requestUpdatePosibleLead == "SUCCESS") {
      Swal.fire({
        icon: "success",
        title: "Cambio exitoso",
        text: "Se ha actualizado el lead con éxito",
        onAfterClose: () => {
          this.hideEditCommentModal();
          this.hideEditPhoneNumberModal();
          this.hideModalAsignacionBroker();
          getPosiblesLeads(filtroNombre, filtroContactado, filtroDiasUltimoContacto, filtroBroker, sortBy, page, rowsPerPage);
        },
      });        
    }    

    if (requestGetPosiblesLeads === "ERROR") {
      Swal.fire("Error", "No se han podido cargar los vendedores", "error");
    }

    if (requestUploadPosiblesLeads === "ERROR") {
      Swal.fire("Error", errorMessage, "error");
    }

    if (requestUpdatePosibleLead === "ERROR") {
      Swal.fire("Error", errorMessage, "error");
    }

    if (requestCambiarContactadoPosibleLead === "ERROR") {
      Swal.fire("Error", errorMessage, "error");
    }
  }

  render() {
    const { posiblesLeads, requestUpdatePosibleLead, usuariosFiltrados } = this.props;
    const { collapse, isFilterOpen, ready, brokerLead, leadSeleccionado, page, rowsPerPage, isEditPhoneNumberModalOpen, isEditCommentModalOpen,
      isAssignBrokerModalOpen, nuevoBroker, comment, phoneNumber } = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    let idBrokerFilter = user && user.tipoCuenta === 10 ? user.userId : "";
    
    const StyledTableCell = withStyles(theme => ({
      head: {
        backgroundColor: "#fff",
        color: "#000",
      },
      body: {
        fontSize: 12
      }
    }))(TableCell);

    let brokersOptionsFilter = [];
    brokersOptionsFilter.push({
      value: "",
      label: "Todos"
    });

    usuariosFiltrados && usuariosFiltrados.length > 0 && usuariosFiltrados.forEach(u => 
      brokersOptionsFilter.push({
        value: u.id,
        label: `${u.nombres} ${u.apellidos}`
      })
    );

    return (
      <div>
        { this.feedback() }
        <Row className="mb-3">
          <Col>
            <Button
              variant="contained"
              startIcon={<FilterList />}
              onClick={() => this.toggleFilter()}
              color="primary"
            >
              Filtrar
            </Button>
          </Col>          
          <Col className={"align-right"}>
            <Button variant="contained" onClick={this.toggleUpload}>
              Subir archivo posibles leads
            </Button>            
          </Col>
        </Row>       

        <Row>
          <Col>
            <Collapse open={collapse}>
              <div className="p-3 mt-3 border rounded">
                <ul>
                  <li>Sólo se aceptan archivos con formato .xlsx</li>
                  <li>Archivos con tamaño inferior a 5 mb</li>
                  <li>
                    Cabeceras requeridas: Comuna, Nombre, Precio, SuperficieTotal,
                    SuperficieUtil, Dormitorios, TipoPropiedad, Banios,
                    Estacionamientos, Barrio, Link, UF_m2, Operacion, PrecioUF
                    <br />
                    <strong>Nota: Las cabeceras son requeridas, pero pueden venir sin valor.</strong>
                  </li>
                </ul>                
                <FileUpload
                  acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                  onDrop={this.onDrop}
                  sizeLimit={5000000}
                  maxFiles={1}
                />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<BackupOutlinedIcon />}
                  disabled={!ready}
                  onClick={() =>
                    this.onSubmitFile(ready)
                  }
                >
                  Subir archivo
                </Button>
              </div>
            </Collapse>
          </Col>
        </Row>

        <Row>
          <Col>
            <Collapse open={isFilterOpen}>
              <div className="p-3 mt-3 border rounded">
                <Formik
                  initialValues={{
                    nombre: "",
                    contactado: "",
                    diasDesdeUltimoContacto: "",
                    idBroker: idBrokerFilter,
                    sortBy: "",
                  }}
                  onSubmit={this.onClickAplicarFiltro}
                >
                  {({
                    values,
                    errors,
                    handleSubmit,
                    setFieldValue
                  }) => (
                    <>
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col>
                            <FieldGroup
                              name="nombre"
                              label="Nombre"
                            />
                          </Col>
                          <Col>
                            <SelectFieldGroup
                              label="Estado contacto"
                              name="contactado"
                              arrayOps={[
                                { value: "", label: "Todos" },
                                { value: "1", label: "Contactado" },
                                {
                                  value: "0",
                                  label: "Sin contactar",
                                },
                              ]}
                            />
                          </Col>
                          { user && user.tipoCuenta !== 10 && (
                            <Col>
                              <SelectFieldGroup
                                label="Broker"
                                name="idBroker"
                                arrayOps={brokersOptionsFilter}
                              />
                            </Col>
                          )}                          
                        </Row>
                        <Row>
                          <Col md={4}>
                            <SelectFieldGroup
                              label="Dias desde ultimo contacto"
                              name="diasDesdeUltimoContacto"
                              arrayOps={[
                                { value: "", label: "Todos" },
                                { value: "5", label: "Más de 5 días desde último contacto" },
                                { value: "10", label: "Más de 10 días desde último contacto" },
                                { value: "20", label: "Más de 20 días desde último contacto" }
                              ]}
                            />
                          </Col>
                          <Col md={8}>
                            <SelectFieldGroup
                              label="Ordenar por"
                              name="sortBy"
                              arrayOps={[
                                { value: "createdAtDescending", label: "Fecha de subida, desde más nuevo al más antiguo" },
                                { value: "createdAtAscending", label: "Fecha de subida, desde más antiguo al más nuevo" },
                                { value: "fechaUltimoContactoAscending", label: "Dias desde ultimo contacto, desde el más reciente al más antiguo" },
                                { value: "fechaUltimoContactoDescending", label: "Dias desde ultimo contacto, desde el más antiguo al más reciente" },
                              ]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="center text-center">
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                Aplicar filtro
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </Collapse>
          </Col>
        </Row>

        <Row>      
          <Col>
            <TableContainer component={Paper} className={"hola"}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="center">Operación</StyledTableCell>
                    <StyledTableCell align="left" style={{minWidth:'100px'}}>Fecha subida</StyledTableCell>
                    <StyledTableCell align="left">Días desde último contacto</StyledTableCell>
                    <StyledTableCell align="left">Teléfono</StyledTableCell>
                    <StyledTableCell align="left">Comentario</StyledTableCell>
                    <StyledTableCell align="left">Contactado</StyledTableCell>
                    <StyledTableCell align="left" style={{minWidth:'200px'}}>Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {posiblesLeads && posiblesLeads.results && Array.isArray(posiblesLeads.results) && posiblesLeads.results.length == 0 
                  && posiblesLeads.results.map((row) => (
                    <TableRow>
                      No se encontraron resultados
                    </TableRow>
                  ))}

                  {posiblesLeads && posiblesLeads.results && Array.isArray(posiblesLeads.results) && posiblesLeads.results.length > 0 
                  && posiblesLeads.results.map((row) => {
                    let lastContactText = "";
                    let textoCantidadDias = this.getTextoCtdadDias(row.diasDesdeUltimoContacto);
                    
                    if (row.contactado || moment(row.fechaCambioContactado).format("YYYY-MM-DD") !== moment(row.createdAt).format("YYYY-MM-DD")) {
                      lastContactText = `Última vez contactado ${textoCantidadDias}`;
                    } else {
                      lastContactText = `Nunca ha sido contactado desde que fue subido (${textoCantidadDias})`;
                    }

                    return (
                      <TableRow key={row.id}>
                        <TableCell align="left">{row.nombre}</TableCell>
                        <TableCell align="center">
                          <Avatar alt={row.operacion}>
                            {row.operacion && row.operacion.charAt(0)}
                          </Avatar>
                        </TableCell>
                        <TableCell align="left">
                          {moment(row.createdAt).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={lastContactText}
                            aria-label="ver dias ultimo contacto"
                          >
                            <IconButton
                              color="primary"
                              aria-label="ver dias ultimo contacto"
                              component="span"
                            >
                              <Badge badgeContent={row.diasDesdeUltimoContacto} color={row.diasDesdeUltimoContacto < 20 ? "primary" : "secondary"}>
                                <CalendarToday />
                              </Badge>
                            </IconButton>
                          </Tooltip>  
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title={!row.telefono ? "Agregar teléfono" : "Editar teléfono"} aria-label="editar">
                            <div 
                              className="div-edit-hover" 
                              onClick={() =>
                                this.showEditPhoneNumberModal(row)
                            }>
                              {row.telefono ? row.telefono : "Sin teléfono"}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">                          
                          <Tooltip title={!row.comentario ? "Agregar comentario" : "Editar comentario"} aria-label="editar">
                            <div 
                              className="div-edit-hover" 
                              onClick={() =>
                                this.showEditCommentModal(row)
                            }>
                              {row.comentario ? row.comentario : "Sin comentario"}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          { row.contactado ? (
                            <FormControlLabel control={<Checkbox defaultChecked />} onClick={() => this.handleClick(row.id)} />
                          ) : (
                            <FormControlLabel control={<Checkbox />} onClick={() => this.handleClick(row.id)} />
                          )}
                        </TableCell>
                        <TableCell align="left">
                        <Tooltip title="Visitar" aria-label="ver pdf">
                          <a href={row && row.link} target="_blank">
                            <IconButton
                              color="primary"
                              aria-label="Visitar"
                              component="span"
                            >
                              <OpenInNew />
                            </IconButton>
                          </a>
                        </Tooltip>
                          <Tooltip title="Ver detalle lead" aria-label="ver">
                            <IconButton
                              color="primary"
                              aria-label="Ver detalle lead"
                              component="span"
                              onClick={() =>
                                this.toggleDetailModal(row)
                              }
                            >
                              <RemoveRedEyeIcon />
                            </IconButton>
                          </Tooltip>
                          {user.tipoCuenta !== 10 ? (
                          <Tooltip
                            title="Ver broker asignado"
                            aria-label="ver broker asignado"
                          >
                            <IconButton
                              color="primary"
                              aria-label="ver broker asignado"
                              component="span"
                              onClick={() =>
                                this.toggleModalAsignacionBroker(row)
                              }
                            >
                              {!row.idBroker ? (
                                <Badge badgeContent={"!"} color="secondary">
                                  <FaceIcon />
                                </Badge>
                              ) : (
                                <FaceIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={posiblesLeads && posiblesLeads.totalResults}
                      page={page}
                      onChangePage={this.handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      SelectProps={{
                        inputProps: { "aria-label": "Cantidad" },
                        native: true,
                      }}
                      labelRowsPerPage={"Resultados por página"}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Col>
        </Row>

        <Modal
          fade={false}
          open={isEditPhoneNumberModalOpen}
          toggle={this.toggleEditPhoneNumberModal}
          size="sm"
        >
          <ModalHeader>Agregar/Editar teléfono</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <label htmlFor="#phoneNumber">Agregar/Editar teléfono para {this.state.leadSeleccionado && this.state.leadSeleccionado.nombre}</label>
                  <FormInput  
                    id="#phoneNumber"
                    value={phoneNumber}
                    onChange={(e) =>
                      this.handlePhoneNumberChange(e)
                    }
                    className={"mt-0"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.updatePhoneNumber()}
                  disabled={
                    phoneNumber == "" ||
                    this.props.requestUpdatePosibleLead == "LOADING"
                  }
                >
                  {this.props.requestUpdatePosibleLead == "LOADING"
                    ? "Cargando..."
                    : "Guardar"}
                </Button>
              </Col>
            </Row>            
          </ModalBody>
        </Modal>

        <Modal
          fade={false}
          open={isEditCommentModalOpen}
          toggle={this.toggleEditCommentModal}
          size="sm"
        >
          <ModalHeader>Agregar/Editar comentario</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <label htmlFor="#comentario">Agregar/Editar comentario para {this.state.leadSeleccionado && this.state.leadSeleccionado.nombre}</label>
                  <FormTextarea 
                    id="#comentario"
                    value={comment}
                    onChange={(e) =>
                      this.handleCommentChange(e)
                    }
                    className={"mt-0"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.updateComment()}
                  disabled={
                    comment == "" ||
                    this.props.requestUpdatePosibleLead == "LOADING"
                  }
                >
                  {this.props.requestUpdatePosibleLead == "LOADING"
                    ? "Cargando..."
                    : "Guardar"}
                </Button>
              </Col>
            </Row>            
          </ModalBody>
        </Modal>

        <Modal
          fade={false}
          open={isAssignBrokerModalOpen}
          toggle={this.toggleModalAsignacionBroker}
        >
          <ModalHeader>Ver, asignar o cambiar broker</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                Lead:{" "}
                {leadSeleccionado && leadSeleccionado.nombre}
                <br />
                {brokerLead
                  ? `Broker asignado para este lead: ${
                    brokerLead.nombres
                    } ${brokerLead.apellidos}`
                  : "Esta lead no tiene un broker asignado"}
              </Col>
            </Row>
            <Row className="mb-3 mt-2">
              <Col>
                <FormSelect
                  onChange={(e) => this.handleCambioBroker(e)}
                  value={nuevoBroker}
                >
                  {this.selectBrokers()}
                </FormSelect>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.asignarBroker}
                  disabled={
                    nuevoBroker == "" ||
                    requestUpdatePosibleLead == "LOADING"
                  }
                >
                  {requestUpdatePosibleLead == "LOADING"
                    ? "Cargando..."
                    : "Confirmar cambio de broker"}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal
          fade={false}
          open={this.state.isDetailModalOpen}
          toggle={this.toggleDetailModal}
          size="lg"
        >
          <ModalHeader>Detalles lead</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <h5 className="mb-0">
                  Datos de la propiedad
                </h5>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <List
                  dense={true}
                  sx={{
                    width: "100%",
                    maxWidth: 240,
                    bgcolor: "background.paper",
                  }}
                  className={"px-0"}
                >
                  <ListItem key={1} disablePadding className={"px-0"}>
                    <ListItemText
                      id={1}
                      primary={"Ubicación"}
                      secondary={leadSeleccionado && `Barrio ${leadSeleccionado.barrio}, ${leadSeleccionado.comuna}`}
                    />
                  </ListItem>
                  <ListItem key={2} disablePadding className={"px-0"}>
                    <ListItemText
                      id={2}
                      primary={"Superficie util"}
                      secondary={leadSeleccionado && leadSeleccionado.superficieUtil}
                    />
                  </ListItem>
                  <ListItem key={3} disablePadding className={"px-0"}>
                    <ListItemText
                      id={3}
                      primary={"Dormitorios"}
                      secondary={leadSeleccionado && leadSeleccionado.dormitorios}
                    />
                  </ListItem>
                  <ListItem key={4} disablePadding className={"px-0"}>
                    <ListItemText
                      id={4}
                      primary={"Estacionamientos"}
                      secondary={leadSeleccionado && leadSeleccionado.estacionamientos}
                    />
                  </ListItem>
                  { leadSeleccionado && leadSeleccionado.precio && (
                    <ListItem key={5} disablePadding className={"px-0"}>
                      <ListItemText
                        id={5}
                        primary={"Precio"}
                        secondary={`${leadSeleccionado.precio} ${leadSeleccionado.precio.toString().length < 6 ? "UF" : "CLP"}`}
                      />
                    </ListItem>
                  ) }
                  
                </List>
              </Col>
              <Col>
                <List
                  dense={true}
                  sx={{
                    width: "100%",
                    maxWidth: 240,
                    bgcolor: "background.paper",
                  }}
                  className={"px-0"}
                >
                  <ListItem key={1} disablePadding className={"px-0"}>
                    <ListItemText
                      id={1}
                      primary={"Tipo propiedad"}
                      secondary={leadSeleccionado && leadSeleccionado.tipoPropiedad}
                    />                    
                  </ListItem>
                  <ListItem key={2} disablePadding className={"px-0"}>
                    <ListItemText
                      id={2}
                      primary={"Superficie total"}
                      secondary={leadSeleccionado && leadSeleccionado.superficieTotal}
                    />
                  </ListItem>
                  <ListItem key={3} disablePadding className={"px-0"}>
                    <ListItemText
                      id={3}
                      primary={"Baños"}
                      secondary={leadSeleccionado && leadSeleccionado.banios}
                    />
                  </ListItem>
                  <ListItem key={4} disablePadding className={"px-0"}>
                    <ListItemText
                      id={4}
                      primary={"Operación"}
                      secondary={leadSeleccionado && leadSeleccionado.operacion}
                    />
                  </ListItem>
                </List>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-0">
                  Datos del propietario
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <List
                  dense={true}
                  sx={{
                    width: "100%",
                    maxWidth: 240,
                    bgcolor: "background.paper",
                  }}
                  className={"px-0"}
                >
                  <ListItem key={1} disablePadding className={"px-0"}>
                    <ListItemText
                      id={1}
                      primary={"Nombre"}
                      secondary={leadSeleccionado && leadSeleccionado.nombre}
                    />
                  </ListItem>
                  <ListItem key={2} disablePadding className={"px-0"}>
                    <ListItemText
                      id={2}
                      primary={"Contactado"}
                      secondary={leadSeleccionado && leadSeleccionado.contactado ? "Si" : "No"}
                    />
                  </ListItem>
                  <ListItem key={3} disablePadding className={"px-0"}>
                    <ListItemText
                      id={3}
                      primary={"Comentario"}
                      secondary={leadSeleccionado && leadSeleccionado.comentario ? leadSeleccionado.comentario : "Sin comentario"}
                    />
                  </ListItem>
                </List>
              </Col>
              <Col>
                <List
                  dense={true}
                  sx={{
                    width: "100%",
                    maxWidth: 240,
                    bgcolor: "background.paper",
                  }}
                  className={"px-0"}
                >
                  <ListItem key={1} disablePadding className={"px-0"}>
                    <ListItemText
                      id={1}
                      primary={"Teléfono"}
                      secondary={leadSeleccionado && leadSeleccionado.telefono ? leadSeleccionado.telefono : "Sin telefono"}
                    />                    
                  </ListItem>
                  { leadSeleccionado && (leadSeleccionado.contactado || 
                    moment(leadSeleccionado.fechaCambioContactado).format("YYYY-MM-DD") !== moment(leadSeleccionado.createdAt).format("YYYY-MM-DD")) ? (
                      <ListItem key={2} disablePadding className={"px-0"}>
                        <ListItemText
                          id={2}
                          primary={"Última vez contactado"}
                          secondary={leadSeleccionado && `${moment(leadSeleccionado.fechaCambioContactado).format('DD/MM/YYYY')}, ${this.getTextoCtdadDias(leadSeleccionado.diasDesdeUltimoContacto)}`}
                        />
                      </ListItem>
                    ) : (
                      <ListItem key={2} disablePadding className={"px-0"}>
                        <ListItemText
                          id={2}
                          primary={"Última vez contactado"}
                          secondary={"Nunca ha sido contactado"}
                        />
                      </ListItem>
                    ) }
                  <ListItem key={3} disablePadding className={"px-0"}>
                    <ListItemText
                      id={3}
                      primary={"Broker asignado"}
                      secondary={brokerLead ? `${brokerLead.nombres} ${brokerLead.apellidos}` : "Sin broker asignado"}
                    />
                  </ListItem>             
                </List>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posiblesLeads: state.app.posiblesLeads,
    usuariosFiltrados: state.app.usuariosFiltrados,
    requestUploadPosiblesLeads: state.app.requestUploadPosiblesLeads,
    requestGetPosiblesLeads: state.app.requestGetPosiblesLeads,
    requestUpdatePosibleLead: state.app.requestUpdatePosibleLead,
    requestCambiarContactadoPosibleLead: state.app.requestCambiarContactadoPosibleLead,
    errorMessage: state.app.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uploadPosiblesLeads: (file) => dispatch(uploadPosiblesLeads(file)),
  getPosiblesLeads: (nombre, contactado, ultimoContacto, idBroker, sortBy, page, pageSize) => 
    dispatch(getPosiblesLeads(nombre, contactado, ultimoContacto, idBroker, sortBy, page, pageSize)),
  cambiarContactadoPosibleLead: (id) => dispatch(cambiarContactadoPosibleLead(id)),
  updatePosibleLead: (id, data) => dispatch(updatePosibleLead(id, data)),getUsuariosFiltrados: (tipoCuenta) =>
    dispatch(getUsuariosFiltrados(tipoCuenta)),
  dispatch: (action) => {
    dispatch(action);
  },
});

PosiblesLeads = connect(
  mapStateToProps,
  mapDispatchToProps
)(PosiblesLeads);

export default PosiblesLeads;
